import { FC, useRef, useState, useEffect } from 'react'
import styled, { css } from "styled-components";
import useIntersectionObserver, { IntersectionObserverArgs } from '../hooks/useIntersectionObserver'
import { appearFromBotton } from './animations';

interface ISection {
  padding?: string;
  backgroundImage: string;
  id: string;
  intersectionObserver?: IntersectionObserverArgs
  fullScreen?: boolean
}
export const Section: FC<ISection> = ({ children, padding, backgroundImage, id, intersectionObserver = {}, fullScreen = true }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, intersectionObserver)
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!!entry?.isIntersecting) {
      setIsVisible(true)
    }
  }, [entry])



  return (
    <StyledSection ref={ref} padding={padding} backgroundImage={backgroundImage} id={id} isVisible={isVisible} fullScreen={fullScreen}>
      {children}
    </StyledSection>
  )
}

interface IStyledSection {
  padding?: string;
  height?: string;
  backgroundImage: string;
  isVisible?: boolean
  fullScreen: boolean
}

const StyledSection = styled.section<IStyledSection>`
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ fullScreen }) => fullScreen && `min-height: 100vh;`};
  left: 0;
  right: 0;
  background: url(${({ backgroundImage }) => backgroundImage}) fixed 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  :nth-child(n) {
    opacity: 0;
    ${({ isVisible }) => showAnimation(isVisible)}
  }
`;

const showAnimation = (isVisible?: boolean) => {
  return !isVisible ? `` : css`
    animation: ${appearFromBotton} 2s forwards;
    `;
}