import { FunctionComponent, MouseEvent } from 'react'
import styled from 'styled-components'

interface Props {
  show: (display: boolean) => void
  loading: boolean
  deleteFunction: () => Promise<void>
  isSlider?: boolean
}

export const DeletePopUp: FunctionComponent<Props> = ({ show, loading, deleteFunction, isSlider = false }) => {

  const handleClick = async (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    e.stopPropagation()
    await deleteFunction()
    show(false)
  }

  const handleCancel = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    e.stopPropagation()
    show(false)
  }

  return <Container onClick={e => e.stopPropagation()} isSlider={isSlider}>
    <p>Está seguro que desea eliminar?</p>
    <ButtonLine>
      <UploadButton show={loading} disabled={loading} onClick={(e) => handleClick(e)}>Eliminar</UploadButton>
      <CancelButton disabled={loading} onClick={(e) => handleCancel(e)}>Cancelar</CancelButton>
    </ButtonLine>
  </Container>
}

interface ContainerProps {
  isSlider: boolean
}

const Container = styled.div<ContainerProps>`
z-index:9999999999;
color:black;
text-align:center;
border-radius:15px;
padding:15px;
position:fixed;
top:50%;
left: ${props => props.isSlider ? '' : '50%'};
transform:${props => props.isSlider ? 'translate(0,-50%)' : 'translate(-50%,-50%)'};;
display: flex;
justify-content: center;
align-content: center;
flex-direction: column;
/* height: 100%; */
/* width: 100%; */
background-color:white;
box-shadow: 0px 0px 4px 1px black;
`

const ButtonLine = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  gap:5px;
  @media(max-width:640px){
    gap:3px;
  }
`

interface ButtonProps {
  show?: boolean
}

const UploadButton = styled.button<ButtonProps>`
    display: flex;
    align-items: center;
    justify-content: space-around;
    ${props => !props.show && 'cursor:pointer'};
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: 2px solid white;
    ${props => !props.show ? 'background: green' : 'background: #004100'};
    color: white;
    font-weight: bold;
    transition-duration:0.4s;
    @media (max-width: 640px) {
    font-size: 10px;
    width: 57px;
    height: 25px;
  }
`
const CancelButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: 2px solid white;
    background: red;
    color: white;
    font-weight: bold;
    transition-duration:0.4s;
    @media (max-width: 640px) {
    font-size: 10px;
    width: 57px;
    height: 25px;
  }
`