import { ChangeEvent, FormEvent, FunctionComponent, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { RedirectTo } from "./../models"
import { useNavigate } from "react-router-dom"
import { Button } from "./Button"
import { FaImage, FaTrash } from "react-icons/fa";
import { Card, NewCard } from "../models/SliderCard"
import { UserContext } from "../hooks/userContext";
import { RedirectToPopUp } from "./RedirectToPopUp";
import { AiOutlineLink } from "react-icons/ai";
import { DeletePopUp } from "./DeletePopUp";


interface Props {
  backgroundImage: string;
  title: string;
  content: string;
  buttonText: string;
  redirectTo: RedirectTo;
  displayOrder: number;
  handleUpdateCard: (newCard: NewCard | Card) => void
  deleteCard: () => Promise<void>
  createCard: () => void
  _id: string
  numberOfCards: number
  sliderName: string
  index: number
  loading: boolean
}
export const SliderCard: FunctionComponent<Props> = ({ loading, backgroundImage, title, content, buttonText, redirectTo, displayOrder, numberOfCards, _id, sliderName, index, handleUpdateCard: updateCardMutation, deleteCard, createCard }) => {
  const navigate = useNavigate()
  const [prevBackground, setPrevBackground] = useState(backgroundImage)
  const [prevTitle, setPrevTitle] = useState(title)
  const [prevContent, setPrevContent] = useState(content)
  const [prevButtonText, setPrevButtonText] = useState(buttonText)
  const [prevRedirectTo, setPrevRedirectTo] = useState(redirectTo)
  const [prevDisplayOrder, setPrevDisplayOrder] = useState(displayOrder)
  const [redirectToPopUp, setRedirecToPopUp] = useState(false)
  const [deletePopUp, setDeletePopUp] = useState(false)

  useEffect(() => setPrevDisplayOrder(displayOrder), [displayOrder])


  const { isAdmin } = useContext(UserContext)

  const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target
    const reader = new FileReader();
    reader.onload = () => {
      setPrevBackground(reader.result as string)
    }
    reader.readAsDataURL(files![0])
  }

  const numberOfCardArray = Array.from(Array(numberOfCards).keys())

  const handleUpdateCard = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const newCard: NewCard | Card = {
      backgroundImage: prevBackground,
      title: prevTitle,
      content: prevContent,
      buttonText: prevButtonText,
      redirectTo: prevRedirectTo,
      displayOrder: prevDisplayOrder,
      sliderName
    }
    await updateCardMutation(newCard)
  }

  const handleDeletePopUp = (display: boolean) => {
    setDeletePopUp(display)
  }

  return (
    <CardContent backgroundImage={prevBackground} as={isAdmin ? 'form' : 'div'} onSubmit={handleUpdateCard}>
      {deletePopUp && <DeletePopUp isSlider={true} loading={loading} show={handleDeletePopUp} deleteFunction={deleteCard} />}
      <InnerContent>
        {isAdmin ?
          <>
            <ShadowInput value={prevTitle} onChange={(e) => setPrevTitle(e.target.value)} required></ShadowInput>
            <TitleInput contentEditable={!loading} dangerouslySetInnerHTML={{ __html: prevTitle }} onBlur={(e) => setPrevTitle(e.currentTarget.textContent || "")}></TitleInput>
          </>
          : <Title>{title}</Title>}
        {isAdmin ?
          <>
            <ShadowInput value={prevContent} onChange={(e) => setPrevContent(e.target.value)} required></ShadowInput>
            <ContentInput contentEditable={!loading} dangerouslySetInnerHTML={{ __html: prevContent }} onBlur={(e) => setPrevContent(e.currentTarget.textContent || "")}></ContentInput>
          </>
          : <Content>{content}</Content>}
        {isAdmin
          ? <div style={{ display: 'flex' }}>
            <ButtonInput value={prevButtonText} onChange={(e) => setPrevButtonText(e.target.value)}></ButtonInput>
            <AiOutlineLink onClick={() => setRedirecToPopUp(true)} style={{ cursor: 'pointer', zIndex: '99999' }} /> </div >
          : redirectTo.type === "route"
            ? <Button onClick={() => navigate(redirectTo.value)}>{buttonText}</Button>
            : <AnchorButton href={redirectTo.value}>{buttonText}</AnchorButton>
        }
      </InnerContent>
      {isAdmin && <ButtonsContainer>
        <ButtonLine>
          {index === numberOfCards - 1 &&
            <CreateButton onClick={createCard}>
              Nueva
            </CreateButton>}
          <DeleteButton onClick={() => setDeletePopUp(true)}>
            <FaTrash />
          </DeleteButton>
        </ButtonLine>
        <SelectPosition value={prevDisplayOrder.toString()} onChange={(e) => setPrevDisplayOrder(Number(e.target.value))}>
          {numberOfCardArray.map((val, i) => {
            return <option key={i} value={val}>Posicion {val}</option>
          })}
        </SelectPosition>
        <CustomButton>
          CambiarFondo
          <FaImage style={{ fontSize: '25px', margin: '10px' }} />
          <ShadowInput type='file' required={prevBackground ? false : true} onChange={uploadFile} accept="images/*" />
        </CustomButton>
        <ButtonLine>
          <UploadButton type="submit" show={loading} disabled={loading}>{_id ? 'Actualizar' : 'Crear'}</UploadButton>
          <CancelButton disabled={loading}>Cancelar</CancelButton>
        </ButtonLine>
      </ButtonsContainer>}
      {
        redirectToPopUp && <RedirectToPopUp
          index={index}
          setRedirecToPopUp={setRedirecToPopUp}
          setPrevRedirectTo={setPrevRedirectTo}
          prevRedirectTo={prevRedirectTo} />
      }
    </CardContent>)
}

const InnerContent = styled.div`
  max-width: 98vw;
  padding: 0 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba(225,0,0,0.7);
  padding-top: 35px;
  padding-bottom: 35px;
  border-radius: 18px;
  box-shadow: 14px 14px 20px #0000008c;
  transition: all 1s;
  cursor: default;
  &:hover{
    box-shadow: 14px 14px 20px #ffffff8c;
  }
`;

const Title = styled.h1`
  z-index: 10;
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #FFFFFF;
  font-size: 64px;
  line-height: 1;
  user-select: none;
  @media (max-width: 640px) {
    font-size: 32px;
  }
`;

const Content = styled.p`
  user-select: none;
  z-index: 10;
  color: #FFFFFF;
  font-size: 20px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
  @media (max-width: 640px) {
    font-size: 14px;
  }
`;

const AnchorButton = styled.a`
  z-index: 10;
  appearance: none;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  transition: all .5s ease;
  border: none;
  background: #FFD800;
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
  text-decoration: auto;
  color: black; 
  &:hover {
    color: #FFFFFF;
    background: #222222;
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  }
`;

interface CardProps {
  backgroundImage: string
}
const CardContent = styled.div<CardProps>`
  width: 100vw;
  height: calc(100vh - 10vh - 120px);
  color: white;
  background: url(${({ backgroundImage }) => backgroundImage}) 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
`;

//inputs

const TitleInput = styled.div`
  max-width:60vw;
  padding:5px;
  min-width:300px;
  z-index: 10;
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #FFFFFF;
  font-size: 64px;
  line-height: 1;
  border:solid 1px black;
  cursor: text;
  @media (max-width: 640px) {
    font-size: 32px;
    min-width:100px;
  }
`

const ContentInput = styled.div`
  padding:5px;
  min-width:300px;
  border:solid 1px black;
  z-index: 10;
  color: #FFFFFF;
  font-size: 20px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
  cursor: text;
  @media (max-width: 640px) {
    font-size: 14px;
    min-width:100px;
  }
`

const ButtonInput = styled.input`
  z-index: 9999;
  appearance: none;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  transition: all .5s ease;
  border: none;
  background: #FFD800;
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 13px;
  cursor: text;
  text-decoration: auto;
  color: black;
  text-align:center;
`
const ButtonsContainer = styled.div`
  z-index: 10;
  width:100vw;
  display:flex;
  margin-right:5vw;
  align-items:flex-end;
  flex-direction:column;
  gap:5px;
  position:fixed;
  bottom:10px;
`
const CustomButton = styled.label`
  z-index:999;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:#000000a1;
  padding: 5px;
  height: 30px;
  border-radius:10px;
  width: 165px;
  border:solid 1px white;
  cursor: pointer;
  @media (max-width: 640px) {
    font-size: 10px;
    width: 120px;
    height: 25px;
  }
`
const ButtonLine = styled.div`
  display:flex;
  flex-direction:row;
  gap:5px;
  @media(max-width:640px){
    gap:3px;
  }
`

interface ButtonProps {
  show?: boolean
}

const UploadButton = styled.button<ButtonProps>`
    display: flex;
    align-items: center;
    justify-content: space-around;
    ${props => !props.show && 'cursor:pointer'};
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: 2px solid white;
    ${props => !props.show ? 'background: green' : 'background: #004100'};
    color: white;
    font-weight: bold;
    transition-duration:0.4s;
    @media (max-width: 640px) {
    font-size: 10px;
    width: 57px;
    height: 25px;
  }
`
const CancelButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: 2px solid white;
    background: red;
    color: white;
    font-weight: bold;
    transition-duration:0.4s;
    @media (max-width: 640px) {
    font-size: 10px;
    width: 57px;
    height: 25px;
  }
`
const DeleteButton = styled.label`
  z-index:999;
  color:white;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:red;
  padding: 5px;
  height: 30px;
  border-radius:10px;
  width: 80px;
  border:solid 1px white;
  cursor: pointer;
  @media (max-width: 640px) {
    font-size: 10px;
    width: 57px;
    height: 25px;
  }
`
const CreateButton = styled.label`
  z-index:999;
  color:white;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:blue;
  padding: 5px;
  height: 30px;
  border-radius:10px;
  width: 80px;
  border:solid 1px white;
  cursor: pointer;
  @media (max-width: 640px) {
    font-size: 10px;
    width: 57px;
    height: 25px;
  }
`
const SelectPosition = styled.select`
  z-index:999;
  color:white;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:#000000a1;
  padding: 5px;
  height: 30px;
  border-radius:10px;
  width: 165px;
  border:solid 1px white;
  cursor: pointer;
  text-align:center;
  @media (max-width: 640px) {
    font-size: 10px;
    width: 120px;
    height: 25px;
  }
`
const ShadowInput = styled.input`
  z-index:-10;
  width:1px;
  height:1px;
  opacity:0.1;
`