import { components } from "react-select";

export type Option = {
  value: string;
  label: string;
};

export const ValueContainer = ({ children, ...props }: any) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    const plural = values.length === 1 ? "" : "s";
    values = `${values.length} elemento${plural} seleccionado${plural}`;
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};