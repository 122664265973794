import { FunctionComponent } from 'react'
import styled from "styled-components";

interface ImagePopUpProps {
  show: (display: boolean) => void
  index?: number
}

export const PopUp: FunctionComponent<ImagePopUpProps> = ({ show, children, index }) => {

  return <>
    <PopUpBackground onClick={() => show(false)}>
      <Exit>x</Exit>
    </PopUpBackground>
    <ImageContainer>
      {children}
    </ImageContainer>
  </>
}

const PopUpBackground = styled.div`
    z-index:9999999999;
    position:fixed;
    top:0;
    right:0;
    height:100%;
    width:100%;
    background-color:#0000008f;
    display:flex;
    justify-content:flex-end;
    align-items:flex-start;
`

const Exit = styled.h1`
    color:white;
    margin-right:30px;
    cursor: pointer;
    font-size:40px;
`

const ImageContainer = styled.div`
    z-index:9999999999;
    position:fixed;
    top:50%;
    margin-top:-250px;
    margin-left:-290px;
    left:50%;
    display:flex;
    justify-content:center;
    align-items:center;
    @media (max-width: 950px) {
    margin-top:-125px;
    margin-left:-165px;
  }
`