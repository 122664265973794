import { useLazyQuery } from "@apollo/client";
import { createContext, Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from "react";
import { User, UserDataOutput } from "../models";
import { GET_USER } from "../graphql/queries"


interface Context {
    setUser: Dispatch<SetStateAction<User | undefined>>
    isAdmin: boolean
}

export const UserContext = createContext<Context>({
    setUser: () => { },
    isAdmin: false
});


const UserProvider: FunctionComponent = ({ children }) => {

    const [user, setUser] = useState<User>()

    const [getUser] = useLazyQuery<UserDataOutput, { token: string }>(GET_USER)


    useEffect(() => {
        const runQuery = async (token: string) => {
            const userData = await getUser({ variables: { token } })
            setUser(userData.data?.user.node)
        }
        const token = localStorage.getItem("token")
        if (token) {
            runQuery(`Bearer ${token}`)
        }
    }, [getUser])

    return (
        <UserContext.Provider
            value={{ setUser, isAdmin: !!user?.isAdmin }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;

