import { QueryResponse } from "./QueryResponse";
export interface Item {
    _id: string
    subCategory: {
        name: string
        _id: string
    }
    name: string
    images: string[]
    description: string
    additionalData: AdditionalData[]
}
export interface AdditionalData {
    name: string
    images: string[]
    description?: string
    specs: Row[]
}

export interface Row {
    name: string
    value: string
}

export interface ItemData {
    item: QueryResponse<Item>
}

export interface ItemsData {
    items: QueryResponse<Item[]>
}
export interface ItemVars {
    id: string
}

export interface ItemsVars {
    input: {
        itemName: string
        subCategoriesIds: string[]
        skip?: number
        limit?: number
        onlyFirstImage?: boolean
    }
}
export class CreateItemInput {
    images: string[]
    name: string
    description: string
    additionalData: AdditionalData[]
    subCategoryId: string

    constructor(item: ItemInput) {
        this.images = item.images
        this.name = item.name
        this.description = item.description
        this.additionalData = item.additionalData
        this.subCategoryId = item.subCategory._id
    }
}

export class EditItemInput extends CreateItemInput {
    _id: string

    constructor(item: Item) {
        super(item);
        this._id = item._id
    }
}

export interface ItemInput {
    _id?: string
    subCategory: {
        name: string
        _id: string
    }
    name: string
    images: string[]
    description: string
    additionalData: AdditionalData[]
}