import Select from 'react-select';
import { Option } from "./Option"
import { ValueContainer } from './ValueContainer';
import { GroupHeading } from './GroupHeading';

const customStyles = {
  control: (base: any) => ({
    ...base,
    height: 47
  }),
  menuPortal: (provider: any) => ({ ...provider, zIndex: 9999 }),
  menu: (provider: any) => ({ ...provider, zIndex: 9999 }),
};

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const formatGroupLabel = (data: GroupedOption) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
);

export interface GroupedOption {
  readonly label: string;
  readonly options: readonly any[];
}

export const Selector = ({ options, handleChange, optionSelected, name, isLoading }: any) => {
  return <Select
    className="react-select-compact"
    options={options}
    isMulti
    closeMenuOnSelect={false}
    hideSelectedOptions={false}
    components={{
      Option,
      ValueContainer,
      GroupHeading
    }}
    onChange={handleChange}
    value={optionSelected}
    name={name}
    styles={customStyles}
    placeholder={name}
    getOptionLabel={(option) => option.name}
    getOptionValue={option => option._id}
    formatGroupLabel={formatGroupLabel}
    isLoading={isLoading}
  />
}