import styled from "styled-components";

interface CardProps {
  backgroundImage: string
}

export const Card = styled.div<CardProps>`
  width: 270px;
  text-align: center;
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  border-radius: 30px;
  cursor: pointer;
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  transition-duration: 0.6s;
  img {
    transition: transform 300ms;
  }
  &:hover{
    backdrop-filter: blur( 6px );
    box-shadow: 0 8px 32px 0 rgba( 60, 60, 60);
  }
  &:hover img {
    transform: scale(1.1);
  }
`;

export const CardImage = styled.img`
  width: 270px;
  height: 100%;
  object-fit: scale-down;

`

export const CardImageContainer = styled.div`
  width: 270px;
  height: 100%;
  overflow: hidden;
`

export const CardTitle = styled.h3`
  color: white;
`