import { useMutation } from "@apollo/client"
import { Card, NewCard, CreateCardInput, CreateEditCardInput, QueryResponse } from "../models"
import { EDIT_CARD, CREATE_CARD, DELETE_CARD } from "../graphql/mutations"
import { NotificationManager } from 'react-notifications';

export const useCardMutation = () => {
    const [editCard, { loading: editLoading, error: editError }] = useMutation<{ editCard: QueryResponse<Card> }, { input: Card }>(EDIT_CARD, {
        onCompleted: () => {
            NotificationManager.success("Slide editada con éxito")
        },
        onError: () => {
            NotificationManager.success("Ha ocurrido un error al editar la slide")
        }
    })
    const [createCard, { loading: createLoading, error: createError }] = useMutation<{ createCard: QueryResponse<Card> }, { input: NewCard }>(CREATE_CARD, {
        onCompleted: () => {
            NotificationManager.success("Slide creada con éxito")
        },
        onError: () => {
            NotificationManager.success("Ha ocurrido un error al crear la slide")
        }
    })
    const [deleteCard, { loading: deleteLoading, error: deleteError }] = useMutation<{ deleteCard: QueryResponse<Card> }, { id: string }>(DELETE_CARD, {
        onCompleted: () => {
            NotificationManager.success("Slide eliminada con éxito")
        },
        onError: () => {
            NotificationManager.success("Ha ocurrido un error al eliminar la slide")
        }
    })

    const updateCard = async (card: Card | NewCard) => {
        if (!(card as Card)?._id) {
            const newCard = new CreateCardInput(card as NewCard)
            const result = await createCard({
                variables: {
                    input: newCard
                }
            })
            return result?.data?.createCard
        }
        else {
            const newCard = new CreateEditCardInput(card as Card)
            const result = await editCard({
                variables: {
                    input: newCard
                }
            })
            return result?.data?.editCard
        }
    }
    const deleteThisCard = async (_id: string) => {
        const result = await deleteCard({
            variables: {
                id: _id
            }
        })
        return result?.data?.deleteCard
    }
    return { loading: editLoading || createLoading || deleteLoading, error: editError || createError || deleteError, updateCard, deleteThisCard }
}

