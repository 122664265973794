import { useEffect, MutableRefObject } from "react";

export const useOuterClick = (ref: MutableRefObject<any>, callback: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: globalThis.MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) callback()
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [callback, ref]);
}