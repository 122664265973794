import styled from "styled-components"
import { SiWhatsapp } from "react-icons/si"
import { beat } from "./animations"
export const Shortcut = () => {
  return (
    <Whatsapp href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP_PHONE}&text=${process.env.REACT_APP_WHATSAPP_TEXT}`} target="_blank"><SiWhatsapp style={{ width: '40px', height: '40px', cursor: "pointer" }} /></Whatsapp>
  )
}

const Whatsapp = styled.a`
  position: fixed;
  bottom: 30px;
  right: 30px;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  animation: ${beat} 1s linear infinite;
  background:
  linear-gradient(#25d366,#25d366) 14% 84%/16% 16% no-repeat,
  radial-gradient(#25d366 60%,transparent 0);
  transition: all 500ms;
  z-index: 11;
  &:hover {
    filter: drop-shadow(2px 4px 6px #4c4c4c);
    transition: filter 200ms;
    ::before {
      content: "Solicita un presupuesto personalizado!";
      position: absolute;
      left: -67px;
      bottom: 80px;
      display: inline-block;
      padding: 3px 6px;
      border-radius: 2px;
      background: #000;
      color: #fff;
      font-size: 12px;
      font-family: sans-serif;
    }
    ::after {
      content: '';
      position: absolute;
      bottom: 65px;
      left: 20px;
      display: inline - block;
      color: #fff;
      border: 8px solid transparent;
      border-top: 8px solid #000;
    }
  }
`