import { FunctionComponent, Dispatch, SetStateAction } from 'react'
import styled from 'styled-components';
import { Row } from '../../models';
import TableRows from "./TableRows"
import { BiAddToQueue } from "react-icons/bi"

interface Props {
  rows: Row[]
  setRowsData: Dispatch<SetStateAction<Row[]>>
  isEditing: boolean
}
export const Table: FunctionComponent<Props> = ({ rows, setRowsData, isEditing }) => {

  const addTableRows = () => {
    const rowsInput = {
      name: '',
      value: '',
    }
    setRowsData([...rows, rowsInput])

  }
  const deleteTableRows = (index: number) => {
    const rowsData = [...rows];
    rowsData.splice(index, 1);
    setRowsData(rowsData);
  }

  const handleChange = (index: number, name: string, value: string) => {
    const rowsInput = [...rows];
    const row = rowsInput[index]
    row[name as keyof Row] = value;
    setRowsData(rowsInput);
  }

  return (
    <Container>
      <h2 style={{ color: 'white' }}>Especificaciones</h2>
      <TableStyled>
        {isEditing && <Header>
          <tr>
            <HeaderActionCell onClick={addTableRows}><BiAddToQueue /> Agregar fila</HeaderActionCell>
            <HeaderCell></HeaderCell>
            <HeaderCell></HeaderCell>
          </tr>
        </Header>}
        <tbody>
          <TableRows isEditing={isEditing} rowsData={rows} deleteTableRows={deleteTableRows} handleChange={handleChange} />
        </tbody>
      </TableStyled>
    </Container>
  )
}

const Container = styled.div`
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 576px) {
    max-width: 540px;
  }
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-y, .75rem);
  margin-right: auto;
  margin-left: auto;
  margin-bottom:20px;
  margin-top:20px;
  padding:20px;
  padding-top:0;
  background-color:#b45724;
  border-radius:10px;
  border: 1px solid grey
`

const TableStyled = styled.table`
  width:100%;
  border-collapse: collapse;
`

const Header = styled.thead`
  height: 40px;
`
const HeaderCell = styled.th`
  padding: 6px 0px;

`

const HeaderActionCell = styled.th`
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 10px;
    color:black;
    &:hover{
      cursor:pointer;
      color:white
    }
    & > svg {
      height: 30px;
      width: 30px;
      color: #2b2626;
      fill: #2b2626;
    }
    &:hover {
      > svg {
        fill: white;
        color: white;
      }
    }
`