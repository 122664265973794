import { ChangeEvent, FormEvent, FunctionComponent, useContext, useState } from "react"
import { FaEdit, FaTrash } from "react-icons/fa"
import styled from "styled-components"
import { usePersonMutation } from "../hooks/usePersonMutation"
import { UserContext } from "../hooks/userContext"
import { NewPerson, Person as PersonType, QueryResponse } from "../models"
import { ButtonSection } from "./ButtonSection"
import Ourteam_default from "../assets/ourteam_default.png"

interface Props {
    _id?: string
    image: string
    name: string
    updatePeople: (result: QueryResponse<PersonType> | undefined, _id?: string) => void
    updateDeletePeople: (result: QueryResponse<PersonType> | undefined, _id?: string) => void
}

export const Person: FunctionComponent<Props> = ({ image, name, _id, updatePeople, updateDeletePeople }) => {

    const { isAdmin } = useContext(UserContext)
    const { loading, updatePerson, handleDelete } = usePersonMutation()

    const [prevName, setPrevName] = useState(name)
    const [prevImage, setPrevImage] = useState(image)
    const [updatedName, setUpdatedName] = useState<string | undefined>()
    const [updatedImage, setUpdatedImage] = useState<string | undefined>()

    const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target
        const reader = new FileReader();
        reader.onload = () => {
            setPrevImage(reader.result as string)
        }
        reader.readAsDataURL(files![0])
    }

    const handleClick = () => {
        setPrevName(updatedName || name)
        setPrevImage(updatedImage || image)
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        let person: PersonType | NewPerson
        if (_id) {
            person = {
                _id: _id,
                name: prevName,
                image: prevImage
            }
        }
        else {
            person = {
                name: prevName,
                image: prevImage
            }
        }
        const result = await updatePerson(person)
        setUpdatedImage(result?.node.image)
        setUpdatedName(result?.node.name)
        updatePeople(result, _id)
        if (!_id) {
            setPrevName('')
            setPrevImage('')
        }
    }

    const deleteItem = async () => {
        if (_id) {
            const result = await handleDelete(_id)
            updateDeletePeople(result, _id)
        }
    }

    return <MapContainer as={isAdmin ? 'form' : 'div'} onSubmit={handleSubmit}>
        {_id && isAdmin && <TrashContainer>
            <FaTrash style={{ cursor: 'pointer' }} onClick={deleteItem} />
        </TrashContainer>}
        <Circle>
            {isAdmin && <label aria-disabled={loading}>
                <FaEdit style={{ position: 'relative', top: '0px', left: '50px', width: '50px', cursor: "pointer" }} />
                <ShadowInput type='file' required={prevImage ? false : true} onChange={uploadFile} accept="images/*" />
            </label>}
            {<Image src={prevImage? prevImage : Ourteam_default} alt={prevImage} width="140" height="140" />}
        </Circle>
        {isAdmin ?
            <>
                <ShadowInput value={prevName} onChange={(e) => setPrevName(e.target.value)} required></ShadowInput>
                <h4 contentEditable={true} dangerouslySetInnerHTML={{ __html: prevName }} onBlur={(e) => setPrevName(e.currentTarget.textContent || "")}
                    style={isAdmin && { border: 'solid black 1px', padding: '0 5px 0px 5px', minWidth: '100px', textAlign: 'center' }}></h4>
            </>
            : <h4>{prevName}</h4>}
        {isAdmin && <ButtonSection _id={_id} loading={loading} handleClick={handleClick}/>}
    </MapContainer>
}


const MapContainer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
`
const TrashContainer = styled.div`
    width:100%;
    height:0px;
    display:flex;
    justify-content:flex-end;
    font-size:24px;
`

const Circle = styled.div`
    width: 200px;
    height:200px;
    overflow: hidden;
    background-color:rgba( 255,255,255,0.25 );
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    border-radius:300px;
    border: 1px solid rgba( 255,255,255,0.18 );
    box-shadow:0 8px 32px 0 rgb(31 38 135 / 37%);
`

const Image = styled.img`
    object-fit: scale-down;
`
const ShadowInput = styled.input`
  z-index:-10;
  width:1px;
  height:1px;
  opacity:0.1;
`
