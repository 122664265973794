import { useMutation } from "@apollo/client"
import { CREATE_PERSON, DELETE_PERSON, EDIT_PERSON } from "../graphql/mutations"
import { NewPerson, Person, QueryResponse } from "../models"
import { NotificationManager } from 'react-notifications';

export const usePersonMutation = () => {
    const [editPerson, { loading: editLoading, error: editError }] = useMutation<{ editPerson: QueryResponse<Person> }, { input: Person }>(EDIT_PERSON, {
        onCompleted: () => {
            NotificationManager.success(`Se editó un miembro del equipo con éxito`)
        },
        onError: () => {
            NotificationManager.success(`Ha ocurrido un error al editar un miembro del equipo`)
        }
    })
    const [createPerson, { loading: createLoading, error: createError }] = useMutation<{ createPerson: QueryResponse<Person> }, { input: NewPerson }>(CREATE_PERSON, {
        onCompleted: () => {
            NotificationManager.success(`Se agregó un miembro del equipo con éxito`)
        },
        onError: () => {
            NotificationManager.success(`Ha ocurrido un error al agregar un miembro del equipo`)
        }
    })
    const [deletePerson, { loading: deleteLoading, error: deleteError }] = useMutation<{ deletePerson: QueryResponse<Person> }, { id: string }>(DELETE_PERSON, {
        onCompleted: () => {
            NotificationManager.success(`Se eliminó un miembro del equipo con éxito`)
        },
        onError: () => {
            NotificationManager.success(`Ha ocurrido un error al eliminar un miembro del equipo`)
        }
    })

    const updatePerson = async (person: Person | NewPerson) => {
        if (!(person as Person)?._id) {
            const result = await createPerson({
                variables: {
                    input: person as NewPerson
                }
            })
            return result?.data?.createPerson
        }
        else {
            const result = await editPerson({
                variables: {
                    input: person as Person
                }
            })
            return result?.data?.editPerson
        }
    }
    const handleDelete = async (_id: string) => {
        const result = await deletePerson({
            variables: {
                id: _id
            }
        })
        return result?.data?.deletePerson
    }
    return { loading: editLoading || createLoading || deleteLoading, error: editError || createError || deleteError, updatePerson, handleDelete }
}