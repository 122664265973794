import { useMutation, useQuery } from "@apollo/client";
import { GET_CATEGORY } from '../graphql/queries'
import { CREATE_CATEGORY, DELETE_CATEGORY, EDIT_CATEGORY } from "../graphql/mutations"
import { Category } from '../models'
import { useState } from "react";
import { CategoryData, CreateCategoryInput, DeleteCategoryInput, EditCategoryInput } from "../models";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from 'react-notifications';

export const useCategory = () => {

    const navigate = useNavigate()
    const [categories, setCategories] = useState<Category[]>([])
    const { loading, refetch } = useQuery<CategoryData>(GET_CATEGORY, {
        fetchPolicy: 'no-cache',
        onCompleted: ({ categories: { message, status, node } }) => {
            if (message) {
                navigate("/not-found", { state: { message, status } })
            }
            setCategories(node)
        }
    })
    const [createCategory, { loading: createLoading, error: createError }] = useMutation<{ createCategory: Category }, CreateCategoryInput>(CREATE_CATEGORY, {
        onCompleted: () => {
            NotificationManager.success("Categoría creada con éxito")
        },
        onError: () => {
            NotificationManager.success("Ha ocurrido un error al crear la categoría")
        }
    });
    const [deleteCategory, { loading: deleteCategoryLoading }] = useMutation<{ deleteCategory: CategoryData }, DeleteCategoryInput>(DELETE_CATEGORY, {
        onCompleted: () => {
            NotificationManager.success("Categoría eliminada con éxito")
        },
        onError: () => {
            NotificationManager.success("Ha ocurrido un error al eliminar la categoría")
        }
    });
    const [editCategory, { loading: editCategoryLoading }] = useMutation<{ editCategory: Category }, EditCategoryInput>(EDIT_CATEGORY, {
        onCompleted: () => {
            NotificationManager.success("Categoría editada con éxito")
        },
        onError: () => {
            NotificationManager.success("Ha ocurrido un error al editar la categoría")
        }
    });

    return { categories, loading, createCategory, createLoading, createError, refetchCategories: refetch, deleteCategory, deleteCategoryLoading, editCategory, editCategoryLoading }
}