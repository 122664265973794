import { FunctionComponent } from 'react';
import styled from 'styled-components'

interface Props {
    _id: string|undefined;
    loading: boolean;
    handleClick: ()=>void
    updateWidth?: string;
    updateFontSize?:string;
    updateHeight?:string;
    cancelWidth?: string;
    cancelFontSize?:string;
    cancelHeight?:string;
}

export const ButtonSection: FunctionComponent<Props> = ({_id, loading, handleClick, updateWidth = '100px', updateFontSize = '30px', updateHeight = '30px', cancelWidth = '80px', cancelFontSize = '10px', cancelHeight = '30px'}) => {
  return (
    <ButtonLine>
            <UploadButton type="submit" show={loading} disabled={loading} width={updateWidth} height={updateHeight} fontSize={updateFontSize}>
                {!_id ? loading ? 'Creando' : 'Crear' : loading ? 'Actualizando' : 'Actualizar'}
            </UploadButton>
            <CancelButton type="button" disabled={loading} onClick={handleClick} width={cancelWidth} height={cancelHeight} fontSize={cancelFontSize}>Cancelar</CancelButton>
    </ButtonLine>
  )
}

interface ButtonProps {
    show?: boolean
    width?: string;
    fontSize?:string;
    height?:string;
}
const UploadButton = styled.button<ButtonProps>`
    display: flex;
    align-items: center;
    justify-content: space-around;
    ${props => !props.show && 'cursor:pointer'};
    width: ${props => props.width};
    height: ${props => props.height};
    border-radius: 10px;
    border: 2px solid white;
    ${props => !props.show ? 'background: green' : 'background: #004100'};
    color: white;
    font-weight: bold;
    transition-duration:0.4s;
    @media (max-width: 640px) {
    font-size: ${props => props.fontSize};;
    width: 57px;
    height: 25px;
  }
`
const CancelButton = styled.button<ButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    width: ${props => props.width};
    height: ${props => props.height};
    border-radius: 10px;
    border: 2px solid white;
    background: red;
    color: white;
    font-weight: bold;
    transition-duration:0.4s;
    @media (max-width: 640px) {
    font-size: 10px;
    width: 57px;
    height: 25px;
  }
`

const ButtonLine = styled.div`
  display:flex;
  flex-direction:row;
  gap:5px;
  justify-content:center;
  align-items:center;
  @media(max-width:640px){
    gap:3px;
  }
`
