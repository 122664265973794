import { FunctionComponent, useState, useRef, useContext, MouseEvent } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom"
// import { FaShoppingCart } from "react-icons/fa"
import LogoIcon from "../../assets/logo.png"
import { GiHamburgerMenu } from "react-icons/gi"
import { useOuterClick } from "../../hooks/useOuterClick";
import { UserContext } from "../../hooks/userContext";
import { AiOutlinePoweroff, AiFillCaretUp } from "react-icons/ai"
import { useCategory } from "../../hooks/useCategory";
import { CustomLoading } from "../Loading";
interface Props {
  expandedNav: boolean;
}

export const Navbar: FunctionComponent<Props> = ({ expandedNav }) => {
  const [showLeftNavbar, setShowLeftNavbar] = useState(false)
  const [expandMobileDropDown, setExpandMobileDropDown] = useState(false)
  const [expandedCategory, setExpandedCategory] = useState("")
  const { setUser, isAdmin } = useContext(UserContext)
  const nav = useRef(null)

  const handleClose = () => {
    setShowLeftNavbar(false)
    setExpandMobileDropDown(false);
    setExpandedCategory("")
  }

  const handleMobileDropDown = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setExpandMobileDropDown(!expandMobileDropDown);
  }

  const handleCategory = (e: MouseEvent<HTMLDivElement>, _id: string) => {
    e.stopPropagation();
    if (expandedCategory === _id) {
      setExpandedCategory("");
      return;
    }
    setExpandedCategory(_id);
  }

  useOuterClick(nav, handleClose)
  const { categories, loading } = useCategory()
  const handleLogOut = () => {
    localStorage.removeItem("token")
    setUser(undefined)
  }

  return (
    <>
      <Container>
        <Logo to="/" ><Icon src={LogoIcon} alt="Logo" width="60" height="31.61" /></Logo>
        <Nav>
          <Item $disableAnimation={false} to="/">Inicio</Item>
          <ItemGroup>
            <Item to="/store" $disableAnimation={true} >
              <OptionLabel style={{ marginRight: "3px" }}>
                Tienda
              </OptionLabel>
              {loading ? <CustomLoading /> : <AiFillCaretUp style={{ transition: "all 0.5s ease" }} />}
            </Item>
            <DropDown>
              {categories.map((category) => {
                return <Category to={`/store?subcategories=${category.subCategories.map(({ _id }) => _id).join("-")}`} key={category._id}>
                  <SubMenuItem>
                    <AiFillCaretUp style={{ transition: "all 0.5s ease" }} /><label style={{ paddingLeft: "3px", cursor: "pointer" }}>{category.name}</label>
                    <NestedDropDown>
                      {category.subCategories.map(({ _id, name }) => {
                        return <SubMenuLink to={`store?subcategories=${_id}`} key={_id}><SubMenuItem>{name}</SubMenuItem></SubMenuLink>
                      })}

                    </NestedDropDown>
                  </SubMenuItem>
                </Category>
              })}

            </DropDown>
          </ItemGroup>
          <AnchorItem href="/#about-us">Sobre Roma</AnchorItem>
          {isAdmin && <ActionItem onClick={handleLogOut}><AiOutlinePoweroff style={{ marginRight: "5px" }} />Salir</ActionItem>}

          {/* <Item to="/cart"><FaShoppingCart /></Item> */}
        </Nav>
      </Container>
      <Container responsive ref={nav}>
        <HamburgerMenu onClick={() => setShowLeftNavbar(!showLeftNavbar)}><GiHamburgerMenu /></HamburgerMenu>
        <Logo to="/" ><Icon src={LogoIcon} alt="Logo" width="60" height="31.61" /></Logo>
        {/* <Item to="/cart"><FaShoppingCart /></Item> */}
        <LeftNavBar expandedNav={expandedNav} showLeftNavbar={showLeftNavbar} onClick={() => handleClose()}>
          <Item style={{ justifyContent: "left" }} to="/" $disableAnimation={false} >Inicio</Item>
          <Column >
            <ActionItem style={{ justifyContent: "left" }} onClick={handleMobileDropDown}>
              <OptionLabel style={{ marginRight: "3px" }}>Tienda</OptionLabel>
              {loading ? <CustomLoading /> : <AiFillCaretUp style={{
                transition: "all 0.5s ease", transform: `${expandMobileDropDown ? "rotate(180deg)" : "rotate(0deg)"}`
              }} />}
            </ActionItem>
            <MobileDropDown expand={expandMobileDropDown} >
              {categories.map((category) => {
                return <Column key={category._id}>
                  <ActionItem style={{ justifyContent: "left", paddingLeft: "50px" }} key={category._id} onClick={(e) => handleCategory(e, category._id)}>
                    <OptionLabel style={{ marginRight: "3px" }}>{category.name}</OptionLabel>
                    <AiFillCaretUp style={{
                      transition: "all 0.5s ease", transform: `${expandedCategory === category._id ? "rotate(180deg)" : "rotate(0deg)"}`
                    }} />
                  </ActionItem>
                  <MobileDropDown expand={expandedCategory === category._id} >
                    {category.subCategories.map(({ _id, name }) => {
                      return <Item to={`store?subcategories=${_id}`} key={_id} onClick={() => handleClose()} style={{ justifyContent: "left", paddingLeft: "70px" }}>
                        <OptionLabel>{name}</OptionLabel>
                      </Item>
                    })}
                  </MobileDropDown>
                </Column>
              })}
            </MobileDropDown>
          </Column>
          <AnchorItem style={{ justifyContent: "left" }} href="/#about-us">Sobre Roma</AnchorItem>
          {isAdmin && <ActionItem onClick={handleLogOut} style={{ justifyContent: "left" }}><AiOutlinePoweroff style={{ marginRight: "5px" }} />Salir</ActionItem>}
        </LeftNavBar>
      </Container>

    </>

  )
}

const Column = styled.div`

`

interface LeftNavBarProps {
  expandedNav: boolean;
  showLeftNavbar: boolean;
}

const LeftNavBar = styled.div<LeftNavBarProps>`
    @media (min-width: 800px) {
      display: none;
    }
    top:  ${({ expandedNav }) => expandedNav ? `var(--expanded-height)` : `var(--colapsed-height)`};
    left: ${({ showLeftNavbar }) => showLeftNavbar ? `0` : `-100vw`};
    position: absolute;
    width: 100vw;
    height: ${({ expandedNav }) => expandedNav ? `calc(100vh - var(--expanded-height))` : `calc(100vh - var(--colapsed-height))`};
    background-color: var(--header-background-color);
    transition: all 1s;
    border-top: solid 2px rgb(255,255,255,50%);
    display: flex;
    flex-direction: column;
    @media (min-width: 400px) {
      width: 400px;
      left: ${({ showLeftNavbar }) => showLeftNavbar ? `0` : `-400px`};
    }
`

const HamburgerMenu = styled.div`
  &:hover {
    color: white;
  }
`

const Logo = styled(Link)`
  margin-left: 20px;
  padding: 2px 7px;
  background: white;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  width: 60px;
`;

const OptionLabel = styled.label`
  cursor: pointer;
`
interface ContainerProps {
  responsive?: boolean
}
const Container = styled.nav<ContainerProps>`
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--header-background-color);
  height: var(--colapsed-height);
  color: #a5a5a5;
  ${({ responsive }) => responsive ? `
    @media (max-width: 800px) {
      padding: 0px 20px;
    }
    @media (min-width: 801px) {
      display: none;
    }
  ` : `
    @media (max-width: 800px) {
      display: none;
    }
    
  `}
`;

const Nav = styled.nav`
  display: flex;
  margin-right: 20px;
  height: 100%;
`

const ItemStyle = css`
  color: #a5a5a5;
  cursor: pointer;
  padding: 10px 10px;
  transition: background-color 0s ease 0s, all 0s ease 0s;
  text-decoration:none; 
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &::before {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 0%;
    content: '';
    height: 3px;
    background-color: white;
    transition: all 250ms ease-in-out;
  }
  @media (max-width: 801px) {
    padding: 10px 20px; 
  }
`
const ItemAnimation = css`
  &:hover {
      color: white;
      &::before {
        width: 100%;
      }
    }
`


interface ItemProps {
  readonly $disableAnimation?: boolean
  readonly $animateIcon?: boolean
}

const Item = styled(Link) <ItemProps>`
  ${ItemStyle}
  ${({ $disableAnimation }) => $disableAnimation ? null : `${ItemAnimation}`}
`;

const AnchorItem = styled.a`
  ${ItemStyle}
  ${ItemAnimation}
`;

const ActionItem = styled.div <ItemProps>`
  ${ItemStyle}
  ${({ $disableAnimation }) => $disableAnimation ? null : `${ItemAnimation}`}
`;

const DropDown = styled.ul`
  position: absolute;
  min-width: 200px;
  transform: translate(-50%,0);
  top: 80px;
  left: 50%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  display: none;
  transition: all 5s ease;
`

interface MobileDropDownProps {
  expand: boolean;
}
const MobileDropDown = styled.ul<MobileDropDownProps>`
  min-width: 200px;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow: hidden;
  transition: all 5s ease;
  display: none;
  ${({ expand }) => expand && `display: block`}
`

const NestedDropDown = styled.ul`
  position: absolute;
  min-width: 200px;
  width: max-content;
  top: 0px;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  display: none;
  transition: all 5s ease;
  right: 100%;
  border-right: solid transparent 5px;
  &:hover,:focus {
    display: block;
  }
`

const ItemGroup = styled.div`
  position: relative;
  &:hover,:focus {
    > ul {
      display: block;
    }
    a > svg {
      transform: rotate(180deg);
    }
  }
  ${ItemStyle}
  ${ItemAnimation}
`

const SubMenuStyle = css`
  list-style: none;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  &:hover,:focus {
    > div > ul {
      display: block;
    }
  }
  background-color: var(--header-background-color);
  ${ItemStyle}
  ${ItemAnimation}
`

const Category = styled(Link)`
  ${SubMenuStyle}
  cursor: pointer;
  &:hover {
    > div > svg {
      transition: all 0.5s ease 0s;
      transform: rotate(-90deg);
    }
  }
`

const SubMenuLink = styled(Link)`
  ${SubMenuStyle}
`

const SubMenuItem = styled.div`
  display: flex;
  align-items: center;
`