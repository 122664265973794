import { useMutation } from "@apollo/client";
import { CREATE_CONTACT_EMAIL } from "../graphql/mutations";

interface CreateContactEmailOutput {
  success: boolean
}

interface CreateContactEmailInput {
  input: {
    name: string
    email: string
    phoneNumber: string
    query: string
  }
}
export const useContact = () => {
  const [createContact, { loading }] = useMutation<CreateContactEmailOutput, CreateContactEmailInput>(CREATE_CONTACT_EMAIL);

  return { createContact, loading }
}
