import styled from "styled-components"
import LogoIcon from "../../assets/logo.png"
import LogoFireFighterIcon from "../../assets/logo-firefighter.png"
import LogoUnitIcon from "../../assets/logo-unit.png"
import { Link } from "react-router-dom"
import { FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa"
import { AiOutlinePhone } from "react-icons/ai"
import { Loading } from "../Loading"
import { Input } from "../Input"
import { Button } from "../Button"
import { FormEvent, useState } from "react"
import { NotificationManager } from 'react-notifications';
import { useContact } from "../../hooks/useContact"
import { useCategory } from "../../hooks/useCategory"

export const Footer = () => {
  const { createContact, loading } = useContact()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [query, setQuery] = useState('')
  const { categories, loading: loadingCategories } = useCategory()

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    await createContact({
      variables: {
        input: {
          name,
          email,
          phoneNumber,
          query
        }
      }
    })
    setName('')
    setEmail('')
    setPhoneNumber('')
    setQuery('')
    NotificationManager.success("Su mensaje ha sido enviado con éxito", "Contacto")
  }

  return <Container>
    <RowContainer>
      <BoxContainer>
        <LogoContainer>
          <Logo to="/" ><img src={LogoIcon} alt="Logo" width="100" height="52.7" /></Logo>
          <Text>ROMA EXTINTORES SRL</Text>
        </LogoContainer>
        <ListContainer>
          {!loadingCategories && categories.map(list => {
            const { name, subCategories } = list
            return <List key={name}>
              {name}
              {subCategories.map(subCategory => {
                return <Item key={subCategory.name} to={`/store?subcategories=${subCategory._id}`}>{subCategory.name}</Item>
              })}
            </List>
          })}
        </ListContainer>
        <SocialMedias>
          <SocialMedia target="_blank" href="https://www.facebook.com/romaextintoressrl/"><FaFacebookSquare /></SocialMedia>
          <SocialMedia target="_blank" href="https://www.instagram.com/romaextintores/"><FaInstagram /></SocialMedia>
          <SocialMedia target="_blank" href="https://www.linkedin.com/company/roma-extintores/"><FaLinkedin /></SocialMedia>
          <SocialMedia mobileOnly target="_blank" href="tel:+598-2304-4174"><AiOutlinePhone /></SocialMedia>
          <MediaText>Atención al cliente: (+598) 2304 41 74</MediaText>
        </SocialMedias>
      </BoxContainer>
      <InputContainer>
        <ContactTitle>Contactanos</ContactTitle>
        <Form onSubmit={handleSubmit} isLoading={loading}>
          <Loading isLoading={loading} side="40px" />
          <Input id="name" label="Nombre" background={!!name ? "#e8f0fe" : "#1f2022"} border="none" boxShadow="0 1px 0 0 rgb(255 255 255 / 10%)" type="string" setValue={setName} value={name} required />
          <Input id="email" label="Email" background={!!email ? "#e8f0fe" : "#1f2022"} border="none" boxShadow="0 1px 0 0 rgb(255 255 255 / 10%)" type="email" setValue={setEmail} value={email} required />
          <Input id="phone-number" label="Teléfono" background={!!phoneNumber ? "#e8f0fe" : "#1f2022"} border="none" boxShadow="0 1px 0 0 rgb(255 255 255 / 10%)" type="string" setValue={setPhoneNumber} value={phoneNumber} required />
          <Input id="query" label="Consulta" background={!!query ? "#e8f0fe" : "#1f2022"} border="none" boxShadow="0 1px 0 0 rgb(255 255 255 / 10%)" type="textarea" setValue={setQuery} value={query} required rows={2} />
          <Button type="submit" background='hsl(206deg 3% 52%)'>Enviar</Button>
        </Form>
      </InputContainer>
    </RowContainer>
    <Line />
    <RelativeContainer>
      <LeftWrapper>
        <img src={LogoFireFighterIcon} alt="Dirección Nacional de Bomberos" title="Dirección Nacional de Bomberos" height="50" width="50" />
        <img src={LogoUnitIcon} alt="Instituto Uruguayo de Normas Técnicas" title="Instituto Uruguayo de Normas Técnicas" height="50" width="50" />
      </LeftWrapper>
      <CustomContainer>
        <MediaText>© 2022 Vulcan Software All rights reserved.</MediaText>
      </CustomContainer>
    </RelativeContainer>
  </Container>
}

const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 60px;
  @media(max-width:640px){
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    height: auto;
    row-gap: 5px;
  }
`
const LeftWrapper = styled.div`
  display: flex;
  position: absolute;
  height:60px;
  display: flex;
  align-items: center;
  column-gap: 10px;  
  @media(max-width:640px){
    position: inherit;
  }
`
const Container = styled.div`
  display:flex;
  justify-content:flex-start;
  align-items:flex-start;
  flex-direction:column;
  position: relative;
  /* gap:20px; */
  background-color:#f5730a;
  padding:30px;
  bottom:0;
  width:100%;
  background-color:hsl(206deg 8% 17%);;
  color:hsl(206deg 3% 52%);
`

const BoxContainer = styled.div`
  width:100%;
  gap:20px;
  display:flex;
  justify-content:flex-start;
  align-items:flex-start;
  flex-direction:column;
`

const InputContainer = styled.div`
  width:100%;
  height:100%;
  gap:20px;
  display:flex;
  justify-content:center;
  align-items:flex-start;
  flex-direction:row;
   @media(max-width:830px){
    flex-direction:column;
    align-items:center;
    gap:30px
  }
`

const RowContainer = styled.div`
  width:100%;
  display:flex;
  justify-content:flex-start;
  align-items:flex-start;
  flex-direction:row;
  height:100%;
  @media(max-width:830px){
    flex-direction:column;
    gap:30px
  }
`
const LogoContainer = styled.div`
  width: 100%;
  display:flex;
  justify-content:flex-start;
  gap:20px;
  align-items:center;
`;

const CustomContainer = styled.div`
    width: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    height: 60px;
    @media(max-width:640px){
      height: auto
    }
`;

const Text = styled.h3`
  margin:0;
  @media(max-width:420px){
    font-size: 16px
  }
  @media(max-width:360px){
    font-size: 15px
  }
`

const ContactTitle = styled.h3`
    margin:0;
    padding-left:20px;
    @media(max-width:830px){
    padding-left:0;
  }
`

const MediaText = styled.p`
    margin:0;
`

const Logo = styled(Link)`
  padding: 2px 7px;
  border-radius: 7px;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const ListContainer = styled.div`
  display:flex;
`

const List = styled.ul`
    margin:0;
    display:flex;
    flex-direction:column;
    font-size:24px;
`
const Item = styled(Link)`
  text-decoration:none;
  color:hsl(206deg 3% 52%);;
  font-size:14px;
`
const SocialMedias = styled.div`
    display: flex;
    gap: 10px;
    align-items:center;
`;

interface SocialMediaProps {
  mobileOnly?: boolean;
}
const SocialMedia = styled.a<SocialMediaProps>`
  width: 30px;
  height: 30px;
  color: inherit;
  @media (min-width: 651px) {
    ${({ mobileOnly }) => mobileOnly && `display: none`};
  }
  & > svg {
    width: 30px;
    height: 30px;
  }
  &:hover {
    > svg {
      fill: white
    }
  }
`;

const Line = styled.div`
  width:100%;
  height:1px;
  margin-top: 20px;
  border:solid 1px;
`
interface FormProps {
  isLoading: boolean
}
const Form = styled.form<FormProps>`
  width:70%;
  display:flex;
  flex-direction:column;
  position: relative;
  gap: 10px;
  ${({ isLoading }) => isLoading && `
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    right: 0;
    bottom: 0;
    z-index: 11;
    border-radius: 30px;
  }
  `}
  
`