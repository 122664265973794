import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from "react"
import styled from "styled-components"
import { Loading } from "./Loading"
import { PopUp } from "./PopUp"
import { Store } from "./RedirectToPopUp/Store"
import { HomePage } from "./RedirectToPopUp/HomePage"
import { External } from "./RedirectToPopUp/External"
import Select from 'react-select'
import { RedirectTo, RedirectType } from "../models"

interface Props {
    setRedirecToPopUp: Dispatch<SetStateAction<boolean>>
    setPrevRedirectTo: Dispatch<SetStateAction<RedirectTo>>
    index: number
    prevRedirectTo: RedirectTo
}

export const RedirectToPopUp: FunctionComponent<Props> = ({ prevRedirectTo, setRedirecToPopUp, setPrevRedirectTo, index }) => {

    const [showInput, setShowInput] = useState<{ value: string, label: string }>()

    useEffect(() => {
        if (prevRedirectTo.type === "route") {
            setShowInput({ value: 'store', label: 'Link a tienda' })
        } else {
            if (prevRedirectTo.value.charAt(0) === '#') {
                setShowInput({ value: 'homePage', label: 'Link a seccion de inicio' })
            }
            else {
                setShowInput({ value: 'external', label: 'Link externo' })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (type: RedirectType, value: string) => {
        setPrevRedirectTo({
            type,
            value
        })
        setRedirecToPopUp(false)
    }

    const show = (display: boolean) => {
        setRedirecToPopUp(display)
    }
    const selectorOptions = [
        {
            value: 'store',
            label: 'Link a tienda'
        },
        {
            value: 'homePage',
            label: 'Link a seccion de inicio'
        },
        {
            value: 'external',
            label: 'Link externo'
        }

    ]

    const handleSelect = (event: any) => {
        setShowInput(event)
    }

    return <PopUp show={show}>
        <Container index={index}>
            <Loading isLoading={false} side="40px" />
            <Title>Generar Link</Title>
            <Select
                options={selectorOptions}
                onChange={handleSelect}
                value={showInput}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
            <PopUpSection>
                {showInput?.value === 'store' && <Store handleSubmit={handleSubmit} value={prevRedirectTo.value} />}
                {showInput?.value === 'homePage' && <HomePage handleSubmit={handleSubmit} value={prevRedirectTo.value} />}
                {showInput?.value === 'external' && <External handleSubmit={handleSubmit} value={prevRedirectTo.type === 'anchor' ? prevRedirectTo.value : undefined} />}
            </PopUpSection>
        </Container>
    </PopUp>
}

interface ContainerProps {
    index: number
}

const Container = styled.div <ContainerProps>`
  z-index:999;
  background-color: white;
  color:black;
  width: 40%;
  position: fixed;
  top: 50%;
  left: ${({ index }) => `${(index * 100 + 50)}vw`};
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  max-width:60vw;
  padding: 15px;
  border-radius: 20px;
  gap: 15px 0px;
  border-bottom: 2px solid;
  @media (max-width: 800px) {
    width: 80%;
  }
`
const Title = styled.h2`
    padding:0;
    margin:0;
    text-align:center;
`

const PopUpSection = styled.div`
    min-height:200px;
`


