/* eslint-disable no-restricted-globals */
import { MouseEvent, useContext } from "react";
import { components } from "react-select";
import { UserContext } from "../../hooks/userContext";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai"
import { StorePopUpContext } from "../../hooks/storePopUp";

export const GroupHeading = (props: any) => {
  const { isAdmin } = useContext(UserContext)
  const { setShowCreateCategory, setShowDeleteCategory, setDefaultCategoryId, setDefaultCategoryName } = useContext(StorePopUpContext)
  const handleEdit = (e: MouseEvent<HTMLLabelElement>) => {
    e.stopPropagation()
    setShowCreateCategory(true)
    setDefaultCategoryName(props.data.name)
    setDefaultCategoryId(props.data._id)
  }

  const handleDelete = (e: MouseEvent<HTMLLabelElement>) => {
    e.stopPropagation()
    setShowDeleteCategory(true)
    setDefaultCategoryName(props.data.name)
    setDefaultCategoryId(props.data._id)
  }

  return (
    <div>
      <components.GroupHeading  {...props}>
        <>
          <label>{props.data.label}</label>
          {isAdmin &&
            <>
              <label onClick={handleEdit} style={{ padding: '10px', cursor: 'pointer' }}>
                <AiOutlineEdit style={{ marginLeft: "10px", alignSelf: "flex-end" }} />
              </label>
              <label onClick={handleDelete} style={{ float: 'right', cursor: 'pointer', paddingInline: '10px' }}>
                <AiOutlineDelete style={{ marginLeft: "10px", alignSelf: "flex-end" }} />
              </label>
            </>
          }
        </>
      </components.GroupHeading >
    </div>
  );
};