import { createContext, Dispatch, FunctionComponent, SetStateAction, useState } from "react";


interface Context {
  setShowCreateSubCategory: Dispatch<SetStateAction<boolean>>
  setShowCreateCategory: Dispatch<SetStateAction<boolean>>
  setShowDeleteSubCategory: Dispatch<SetStateAction<boolean>>
  setShowDeleteCategory: Dispatch<SetStateAction<boolean>>
  showCreateSubCategory: boolean
  showCreateCategory: boolean
  showDeleteSubCategory: boolean
  showDeleteCategory: boolean,
  defaultCategoryId: string
  setDefaultCategoryId: Dispatch<SetStateAction<string>>
  defaultSubCategoryId: string
  setDefaultSubCategoryId: Dispatch<SetStateAction<string>>
  defaultCategoryName: string
  setDefaultCategoryName: Dispatch<SetStateAction<string>>
  defaultSubCategoryName: string
  setDefaultSubCategoryName: Dispatch<SetStateAction<string>>
}

export const StorePopUpContext = createContext<Context>({
  setShowCreateSubCategory: () => { },
  showCreateSubCategory: false,
  showCreateCategory: false,
  setShowCreateCategory: () => { },
  showDeleteSubCategory: false,
  setShowDeleteSubCategory: () => { },
  defaultCategoryId: "",
  setDefaultCategoryId: () => { },
  defaultSubCategoryId: "",
  setDefaultSubCategoryId: () => { },
  defaultCategoryName: "",
  setDefaultCategoryName: () => { },
  defaultSubCategoryName: "",
  setDefaultSubCategoryName: () => { },
  showDeleteCategory: false,
  setShowDeleteCategory: () => { },
});


const StorePopUpProvider: FunctionComponent = ({ children }) => {
  const [showCreateCategory, setShowCreateCategory] = useState(false)
  const [showCreateSubCategory, setShowCreateSubCategory] = useState(false)
  const [showDeleteSubCategory, setShowDeleteSubCategory] = useState(false)
  const [showDeleteCategory, setShowDeleteCategory] = useState(false)
  const [defaultCategoryId, setDefaultCategoryId] = useState<string>("")
  const [defaultSubCategoryId, setDefaultSubCategoryId] = useState<string>("")
  const [defaultCategoryName, setDefaultCategoryName] = useState<string>("")
  const [defaultSubCategoryName, setDefaultSubCategoryName] = useState<string>("")

  return (
    <StorePopUpContext.Provider
      value={{
        showCreateSubCategory,
        setShowCreateSubCategory,
        showCreateCategory,
        setShowCreateCategory,
        showDeleteSubCategory,
        setShowDeleteSubCategory,
        showDeleteCategory,
        setShowDeleteCategory,
        defaultCategoryId,
        setDefaultCategoryId,
        defaultSubCategoryId,
        setDefaultSubCategoryId,
        defaultCategoryName,
        setDefaultCategoryName,
        defaultSubCategoryName,
        setDefaultSubCategoryName
      }}
    >
      {children}
    </StorePopUpContext.Provider>
  );
};

export default StorePopUpProvider;

