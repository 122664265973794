import { FunctionComponent, useRef } from "react";
import { Navbar } from "./Navbar";
import { CompanyData } from "./CompanyData";
import styled from "styled-components";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { Outlet } from "react-router-dom"
export const Header: FunctionComponent = () => {
  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {})
  return (
    <>
      <div ref={ref} />
      <Container colapse={!entry?.isIntersecting} >
        <CompanyData hide={!entry?.isIntersecting} />
        <Navbar expandedNav={!!entry?.isIntersecting} />
      </Container>
      <Outlet />
    </>
  )
}

interface ContainerProps {
  colapse: boolean
}
const Container = styled.header<ContainerProps>`
  --header-background-color: rgb(0,0,0,0.75);
  --colapsed-height: 80px;
  --expanded-height: 120px;
  font-weight: 600;
  position: fixed;
  display: flex;
  flex-direction: column;
  height: ${({ colapse }) => colapse ? `var(--colapsed-height)` : `var(--expanded-height)`};
  transition: all 1s;
  width: 100%;
  color: #a5a5a5;
  z-index: 9999999999;
`;