import styled from "styled-components"
import Select from 'react-select';
import { useSubCategory } from '../../hooks/useSubCategory'
import { FunctionComponent, useEffect, useState } from "react";
import { RedirectType, SubCategory } from "../../models";

interface Props {
    handleSubmit: (type: RedirectType, value: string) => void
    value: string
}

interface ShortSubCategory {
    value?: string
    label?: string
}

export const Store: FunctionComponent<Props> = ({ handleSubmit, value }) => {

    const [selectValue, setSelectValue] = useState<(SubCategory | ShortSubCategory | undefined)[]>()
    const { subCategories } = useSubCategory()

    useEffect(() => {
        const ids = value?.split('=', 2)[1]?.split('-')
        const defaultSelect = ids?.map((id) => subCategories.find((subCategory) => subCategory._id === id))
        setSelectValue((defaultSelect) || null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subCategories])

    const [link, setLink] = useState<string>("")
    const handleSelect = (event: any) => {
        setSelectValue(event)
        setLink(`/store?subcategories=${event.map((e: any) => e._id).join('-')}`)
        if (!event.length) {
            setLink('')
        }
    }

    const submitRoute = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        e.preventDefault()
        handleSubmit('route', link)
    }

    return <Form>
        <Title>Link a la tienda</Title>
        < Select
            options={subCategories}
            getOptionLabel={(subCategory) => subCategory.name}
            getOptionValue={(subCategory) => subCategory._id}
            isMulti
            isLoading={!!!subCategories.length}
            value={selectValue as SubCategory[]}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            onChange={handleSelect} />
        <input
            style={{ width: '1px', height: '1px', opacity: '1%' }}
            value={link}
            onChange={(e) => setLink(e.target.value)}
            required />
        <div style={{ display: 'flex', width: "100%", justifyContent: 'center' }}>
            <Button onClick={(e) => submitRoute(e)}>Guardar</Button>
        </div>
    </Form>
}

const Form = styled.div`
    display:flex;
    flex-direction:column;
    gap: 20px;
`
const Title = styled.h3`
    padding:0;
    margin:0;
`

interface ButtonProps {
    shaddowColor?: string
    disabled?: boolean
}
const Button = styled.button<ButtonProps>`
  width: 45%;
  border: none;
  padding: 12px;
  background: ${({ disabled }) => disabled ? "#9d5731" : "#e5834d"};
  border-radius: 10px;
  color: white;
  font-size: 15px;
  height: 40px;
  cursor: ${({ disabled }) => disabled ? "progress" : "pointer"};
  margin: 0px 0px 10px 0px;
  &:hover{
    box-shadow: ${({ shaddowColor, disabled }) => !disabled && (shaddowColor || "white 0px 0px 4px 0px")} 
  }
  @media (max-width: 800px) {
    width: 90%;
  }
`