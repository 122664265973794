import styled from "styled-components";

interface Props {
  background?: string
}

export const Button = styled.button<Props>`
  z-index: 10;
  appearance: none;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  transition: all .5s ease;
  border: none;
  background: ${props => props?.background || '#FFD800'};
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
  &:hover {
    color: #FFFFFF;
    background: #222222;
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  }
`;