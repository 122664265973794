import { StrictMode } from 'react';
import { render } from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client'
import "./index.css";
import client from './graphql/utils/client'
import UserProvider from './hooks/userContext';
import { HelmetProvider } from 'react-helmet-async';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-TSW59KP'
}

TagManager.initialize(tagManagerArgs)

render(
  <StrictMode>
    <HelmetProvider>
      <ApolloProvider client={client}>
        <UserProvider>
          <App />
        </UserProvider>
      </ApolloProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
