import { FunctionComponent } from "react";
import styled from "styled-components";
import { FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa"
import { AiOutlinePhone } from "react-icons/ai"
interface Props {
  hide: boolean;
}
export const CompanyData: FunctionComponent<Props> = ({ hide }) => {
  return (
    <Container hide={hide}>
      <ContactInfo>Bienvenido a Roma Extintores <Span>Atención al cliente: </Span> (+598) 2304 41 74</ ContactInfo>
      <SocialMedias>
        <SocialMedia hide={hide} target="_blank" href="https://www.facebook.com/romaextintoressrl/"><FaFacebookSquare /></SocialMedia>
        <SocialMedia hide={hide} target="_blank" href="https://www.instagram.com/romaextintores/"><FaInstagram /></SocialMedia>
        <SocialMedia hide={hide} target="_blank" href="https://www.linkedin.com/company/roma-extintores/"><FaLinkedin /></SocialMedia>
        <SocialMedia hide={hide} mobileOnly target="_blank" href="tel:+598-2304-4174"><AiOutlinePhone /></SocialMedia>
      </SocialMedias>
    </Container>
  )
}

const Span = styled.span`
  margin: 0px 3px 0px 18px;
  @media (max-width: 520px) {
    display: none;
  }
`;

const Container = styled.header<Props>`
  height: ${({ hide }) => hide ? `0px` : `calc(var(--expanded-height) - var(--colapsed-height))`};
  width: 100%;
  display: flex;
  background-color: var(--header-background-color);
  justify-content: space-between;
  color: ${({ hide }) => hide ? `transparent` : `#9d9d9e`};
  align-items: center;
  padding: 0px 10px;
  transition: all 1s;
  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
  }
  
`;
const ContactInfo = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;
const SocialMedias = styled.div`
  display: flex;
  gap: 10px;
`;

interface SocialMediaProps {
  mobileOnly?: boolean;
  hide: boolean
}
const SocialMedia = styled.a<SocialMediaProps>`
  width: 20px;
  height: 20px;
  color: ${({ hide }) => hide ? `transparent` : `#9d9d9e`};
  @media (min-width: 801px) {
    ${({ mobileOnly }) => mobileOnly && `display: none`};
  }
  & > svg {
    width: 20px;
  height: 20px;
  }
  &:hover {
    color: white;
  }
`;

