import { FunctionComponent } from "react";
import { FaTrash } from "react-icons/fa";
import styled from "styled-components";
import { Row } from "../../models";
interface Props {
  handleChange: (index: number, name: string, value: string) => void
  rowsData: Row[]
  deleteTableRows: (index: number) => void
  isEditing: boolean
}

const TableRows: FunctionComponent<Props> = ({ rowsData, deleteTableRows, handleChange, isEditing }) => {
  return (
    <>
      {rowsData.map((data, index) => {
        const { name, value } = data;
        return (
          <tr key={index} style={{ padding: 0, margin: 0 }}>
            <td style={{ padding: 0, margin: 0, width: '80%' }}><CellContent disabled={!isEditing} lastRow={rowsData.length - 1 === index} left={true} pair={index % 2 === 0} value={name} onChange={(e) => handleChange(index, "name", e.target.value)} /></td>
            <td style={{ padding: 0, margin: 0, width: '20%' }}><CellContent disabled={!isEditing} lastRow={rowsData.length - 1 === index} left={false} pair={index % 2 === 0} value={value} onChange={(e) => handleChange(index, "value", e.target.value)} /> </td>
            {isEditing && <div style={{ position: 'relative', left: '2px' }}><button className="btn btn-outline-danger" onClick={() => (deleteTableRows(index))}><FaTrash /></button></div>}
          </tr>
        )
      })}
    </>

  )

}

interface ContentProps extends InputProps {
  disabled: boolean
  value?: string | number | readonly string[]
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

const CellContent: FunctionComponent<ContentProps> = ({ left, pair, lastRow, disabled, value, onChange }) => {
  if (disabled) {
    return <Content lastRow={lastRow} left={left} pair={pair}>{value}</Content>
  }
  return <Input lastRow={lastRow} left={left} pair={pair} type="text" value={value} onChange={onChange} />
}
interface InputProps {
  left: boolean
  pair: boolean
  lastRow: boolean
}

const Content = styled.div<InputProps>`
  width:100%;
  font-size: 14px;
  line-height: 20px;
  border-top: grey 1px solid;
  border-left: ${(props) => props.left ? 'grey 1px solid' : 'none'};
  background-color: ${(props) => props.pair ? '#fb9b65' : 'white'};
  border-right:1px solid grey;
  border-bottom: ${(props) => props.lastRow ? 'grey 1px solid' : 'none'};
  text-align: ${(props) => props.left ? 'left' : 'center'};
  padding:10px;
  height:40px;
  margin:0;
  font-weight:600;
  color: black;
`

const Input = styled.input<InputProps>`
  width:100%;
  border-top: grey 1px solid;
  border-left: ${(props) => props.left ? 'grey 1px solid' : 'none'};
  background-color: ${(props) => props.pair ? '#fb9b65' : 'white'};
  border-right:1px solid grey;
  border-bottom: ${(props) => props.lastRow ? 'grey 1px solid' : 'none'};
  text-align: ${(props) => props.left ? 'left' : 'center'};
  padding:10px;
  height:40px;
  margin:0;
  font-weight:600;
  color: black;
`

export default TableRows;