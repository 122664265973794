import React, { memo, useRef } from 'react';
import {
  Dispatch,
  SetStateAction,
  ChangeEvent,
  useState,
  FocusEvent,
} from 'react';
import styled from 'styled-components';

export interface Props<T> {
  id?: string;
  label?: string;
  setValue: Dispatch<SetStateAction<T | undefined>> | ((value: T) => void);
  value: T | undefined;
  type: string;
  column?: string;
  required?: boolean;
  row?: string;
  disabled?: boolean;
  justifyContent?: string;
  callback?: any;
  isDolar?: boolean;
  width?: string;
  margin?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement> | undefined;
  actions?: Actions[];
  rows?: Number;
  background?: string;
  border?: string;
  boxShadow?: string;
}

export enum Actions {
  onChange = 'onChange',
  onBlur = 'onBlur',
}

export const InputComponent = <T extends unknown>({
  id,
  label,
  setValue,
  value,
  type,
  column,
  required = false,
  row,
  justifyContent,
  disabled,
  callback,
  isDolar = false,
  width,
  margin,
  onClick,
  actions = [Actions.onChange],
  rows,
  background,
  border,
  boxShadow,
}: Props<T>) => {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    if (actions.includes(Actions.onBlur) && callback) {
      callback(value);
    }
    if (actions.includes(Actions.onChange)) {
      if (type === 'checkbox') {
        const { checked } = event.target as HTMLInputElement;
        setValue(checked as T);
        if (callback) callback(value);
        return;
      }
      if (type === 'date') {
        setValue(new Date(value) as T);
        if (callback) callback(value);
        return;
      }
      setValue(value as T);
      if (callback) callback(value);
    }
  };

  const handleOnFocus = () => {
    setFocused(true);
  };

  const handleOnBlur = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFocused(false);
    if (actions.includes(Actions.onBlur)) {
      const { value } = event.target;
      setValue(value as T);
      if (callback) callback(value);
    }
  };

  const handleFocusOnLabel = () => {
    inputRef?.current?.focus();
    setFocused(true);
  }

  return (
    <InputContainer
      column={column}
      row={row}
      focused={focused || value != null}
      type={type}
      justifyContent={justifyContent}
      isDolar={isDolar}
      width={width}
      margin={margin}
      disabled={disabled}
    >
      {type !== 'checkbox' && (
        <Label
          focused={focused}
          value={!!value || (type === 'number' && value != null)}
          type={type}
          onClick={handleFocusOnLabel}
          background={background}
          border={border}
        >
          {label}
          {required && ' *'}
        </Label>
      )}
      {type !== 'textarea' ? (
        <StyledInput
          value={getValue(value, type)}
          type={type}
          defaultChecked={!!value}
          onChange={handleChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          id={id}
          focused={focused}
          required={required}
          disabled={disabled}
          onClick={onClick}
          ref={inputRef}
          background={background}
          border={border}
          boxShadow={boxShadow}
        />
      ) : (
        <StyledTextArea
          type={type}
          rows={rows}
          value={value as string | number | readonly string[] | undefined}
          onChange={handleChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          id={id}
          focused={focused}
          required={required}
          disabled={disabled}
          background={background}
          border={border}
          boxShadow={boxShadow}
        />
      )}
      {type === 'checkbox' && (
        <label htmlFor={id}>
          {label}
          {required && ' *'}
        </label>
      )}
    </InputContainer>
  );
};

export const Input = memo(InputComponent) as typeof InputComponent;

const getDateString = (date?: Date): string | undefined => {
  if (!date) {
    return undefined;
  }
  const newDate = new Date(date);
  const strDate = new Date(
    newDate.getTime() - newDate.getTimezoneOffset() * 60000
  )
    .toISOString()
    .slice(0, -1);
  return strDate;
};

const getValue = (
  value: any,
  type: string
): string | number | readonly string[] | undefined => {
  if (type === 'date') {
    if (typeof value === 'object') {
      return (value as Date)?.toISOString().substring(0, 10) as
        | string
        | number
        | readonly string[]
        | undefined;
    }
    return value?.slice(0, 10) as
      | string
      | number
      | readonly string[]
      | undefined;
  }
  if (type === 'datetime-local') {
    if (typeof value === 'object') {
      return getDateString(value);
    }
  }
  return value as string | number | readonly string[] | undefined;
};
interface IStyledInput {
  id?: string;
  type: string;
  focused?: boolean;
  rows?: any;
  background?: string;
  border?: string;
  boxShadow?: string;
}

const StyledInput = styled.input<IStyledInput>`
  border: ${props => props?.border || '1px solid #eee'} ;
  box-shadow:  ${props => props?.boxShadow || 'none'} ;
  border-radius: 0.25rem;
  background: ${props => props.disabled ? "#f2f2f2" : props?.background || 'white'};
  outline: none;
  padding: 12px 3px 12px 15px;
  font-size: 16px;
  transition: all 0.2s ease;
  z-index: 1;
  ${(props) => props.focused && `box-shadow: 0 0 3px blue;`}
  ${(props) => props.type !== 'checkbox' && `width: -webkit-fill-available;`}
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

const StyledTextArea = styled.textarea<IStyledInput>`
  border: ${props => props?.border || '1px solid #eee'} ;
  border-radius: 0.25rem;
  background: ${props => props?.background || 'white'};
  box-shadow:  ${props => props?.boxShadow || 'none'} ;
  outline: none;
  padding: 12px 3px 12px 15px;
  font-size: 16px;
  transition: all 0.2s ease;
  z-index: 1;
  resize: none;
  ${(props) => props.focused && `box-shadow: 0 0 3px blue;`}
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

interface IInputContainer {
  column?: string;
  row?: string;
  focused?: boolean;
  type: string;
  justifyContent?: string;
  isDolar: boolean;
  width?: string;
  margin?: string;
  disabled?: boolean
}
const InputContainer = styled.div<IInputContainer>`
  display: flex;
  position: relative;
  ${({ disabled }) => disabled && "pointer-events:none;"}
  ${(props) => props.width && `width: ${props.width};`}
  transition: all 0.2s ease;
  ${(props) =>
    props.isDolar &&
    `
        &::after {
            position: absolute;
            content: '$';
            top: 11px;
            left: 4px;
        }
    `}
  ${(props) => props.margin && `margin: ${props.margin};`}
    ${(props) => props.column && `grid-column: ${props.column};`}
    ${(props) => props.row && `grid-row: ${props.row};`}
    ${(props) =>
    props.justifyContent && `justify-content: ${props.justifyContent};`}
    ${(props) =>
    !props.focused &&
    props.type !== 'time' &&
    props.type !== 'date' &&
    props.type !== 'datetime-local' &&
    `overflow: hidden;`}
    ${(props) =>
    (props.type === 'string' || props.type === 'textarea') &&
    `flex-direction: column;`}
    ${(props) => props.type === 'checkbox' && `align-items: center;`}
`;

interface ILabel {
  focused: boolean;
  value: boolean;
  type: string;
  background?: string;
  border?: string;
}
const Label = styled.label<ILabel>`
  color: #757575;
  position: absolute;
  top: 11px;
  left: 15px;
  transition: all 0.2s ease;
  z-index: 2;
  white-space: nowrap;
  border-radius: 10%;
  cursor: auto;
  ${(props) =>
    (props.type === 'time' ||
      props.type === 'date' ||
      props.type === 'datetime-local') &&
    `
      font-size: 13px;
      transform: translateY(-23px) translateX(-5px);
      z-index: 2;
      background: ${props?.background || 'white'};
      padding: 0 8px;
      border: ${props?.border || '1px solid #4b4be5'};
    `}
  ${(props) =>
    (props.focused || props.value) &&
    `
      font-size: 13px;
      transform: translateY(-23px) translateX(-5px);
      z-index: 2;
      background: ${props?.background || 'white'};
      padding: 0 8px;
      border: ${props?.border || '1px solid #4b4be5'};
    `}
    ${(props) => props.focused && `color: #4b4be5;`}
`;
