import { useMutation } from "@apollo/client";
import { FormEvent, useContext, useState } from "react";
import styled from "styled-components";
import { EDIT_OURTEAM_TITLE } from "../graphql/mutations";
import { useOurTeam } from "../hooks/useOurTeam";
import { UserContext } from "../hooks/userContext";
import { OurTeam as OurTeamType, Person as PersonType, QueryResponse } from "../models";
import { Loading } from "./Loading";
import { Person } from "./Person";

export const OurTeam = () => {

  const { isAdmin } = useContext(UserContext)

  const { queryLoading, title, people, setPeople } = useOurTeam()
  const [prevTitle, setPrevTitle] = useState(title)

  const [editTeam, { loading: loadingTitleMutation }] = useMutation<{ editTeam: QueryResponse<OurTeamType> }, { title: string }>(EDIT_OURTEAM_TITLE)

  const updatePeople = (result: QueryResponse<PersonType> | undefined, _id?: string) => {
    if (result?.status === 'SUCCESS') {
      const { name, image } = result.node
      if (_id) {
        const newPeople = people
        newPeople.forEach((person) => {
          if (person._id === _id) {
            person.name = name
            person.image = image
          }
        })
        setPeople(newPeople)
      }
      else {
        const newPeople = [...people, { _id: result.node._id, name, image }]
        setPeople(newPeople)
      }
    }
  }

  const updateDeletePeople = async (result: QueryResponse<PersonType> | undefined, _id?: string) => {
    if (result?.status === 'SUCCESS') {
      const newPeople = people.filter((person) => person._id !== _id)
      setPeople(newPeople)
    }
  }

  const submitTitle = async (e: FormEvent) => {
    e.preventDefault()
    await editTeam({
      variables: {
        title: prevTitle
      }
    })
  }
  return <Container>
    {queryLoading && <Loading side='70px' />}
    {isAdmin ?
      <form onSubmit={submitTitle}>
        <ShadowInput value={prevTitle} onChange={(e) => setPrevTitle(e.target.value)} required></ShadowInput>
        <TitleInput contentEditable={true} dangerouslySetInnerHTML={{ __html: title }} onBlur={(e) => setPrevTitle(e.currentTarget.textContent || "")}></TitleInput>
        {isAdmin && <ButtonLine>
          <UploadButton type="submit" show={loadingTitleMutation} disabled={loadingTitleMutation}>
            {loadingTitleMutation ? 'Actualizando' : 'Actualizar'}
          </UploadButton>
        </ButtonLine>}
      </form>
      : <Title>{title}</Title>}
    {!queryLoading && <ImagesContainer>
      {people.map(person => {
        const { name, image, _id } = person
        return <Person
          key={_id}
          image={image}
          name={name}
          _id={_id}
          updatePeople={updatePeople}
          updateDeletePeople={updateDeletePeople} />
      })}
      {isAdmin && <Person
        image={''}
        name={''}
        updatePeople={updatePeople}
        updateDeletePeople={updateDeletePeople} />}
    </ImagesContainer>}
  </Container>

}

const Container = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
`;

const Title = styled.div`
  font-size: 48px;
  font-weight: 600;
  @media (max-width: 640px) {
    font-size: 24px;
    padding: 10px 0px;
  }
`;

const ImagesContainer = styled.div`
    padding:30px;
    width: 100%;
    display: flex;
    justify-content:center;
    align-items: center;
    flex-wrap:wrap;
    gap:50px;
`


const TitleInput = styled.div`
  font-size: 48px;
  font-weight: 600;
  border: solid 1px black;
  @media (max-width: 640px) {
    font-size: 24px;
    padding: 10px 0px;
  }
`

const ShadowInput = styled.input`
  z-index:-10;
  width:1px;
  height:1px;
  opacity:0.1;
`
const ButtonLine = styled.div`
  display:flex;
  flex-direction:row;
  gap:5px;
  @media(max-width:640px){
    gap:3px;
  }
`
interface ButtonProps {
  show?: boolean
}
const UploadButton = styled.button<ButtonProps>`
    display: flex;
    align-items: center;
    justify-content: space-around;
    ${props => !props.show && 'cursor:pointer'};
    width: 100px;
    height: 30px;
    border-radius: 10px;
    border: 2px solid white;
    ${props => !props.show ? 'background: green' : 'background: #004100'};
    color: white;
    font-weight: bold;
    transition-duration:0.4s;
    @media (max-width: 640px) {
    font-size: 10px;
    width: 57px;
    height: 25px;
  }
`