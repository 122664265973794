import { FunctionComponent } from "react"
import styled, { keyframes } from "styled-components"

interface Props {
  isLoading?: boolean
  side: string
  positionAbsolute?: boolean
}

export const Loading: FunctionComponent<Props> = ({ isLoading = true, side, positionAbsolute = true }) => {
  if (!isLoading) {
    return null
  }
  return <Container>
    <Loader side={side} positionAbsolute={positionAbsolute} />
  </Container>
}

const Container = styled.div`
  width:100%;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
`

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
interface LoaderProps {
  side: string;
  positionAbsolute: boolean
}
const Loader = styled.div<LoaderProps>`
  background: linear-gradient(black, #0aebff00 60%);
  mask: radial-gradient(farthest-side,#00000000 calc(100% - 5px),#000 0);
  border-right: 20px solid #000000;
  border-radius: 50%;
  width: ${({ side }) => side};
  height: ${({ side }) => side};
  animation: ${spin} 1s ease-out infinite;
  top:  ${({ side }) => `calc(50% - ${side}/2)`};
  left: ${({ side }) => `calc(50% - ${side}/2)`};
  z-index: 12;
  position:${({ positionAbsolute }) => positionAbsolute ? 'absolute' : ''};
`;

const CustomLoadingStyle = styled.div`
  display: inline-block;
  position: relative;
  height: 12px;
  width: 12px;
  > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    border: 1px solid #fff;
    margin: 0px 7.2px;
    border-radius: 50%;
    animation: ${spin} 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}
> div:nth-child(1) {
    animation-delay: -0.45s;
}
> div:nth-child(2) {
    animation-delay: -0.3s;
}
> div:nth-child(3) {
    animation-delay: -0.15s;
}
`

export const CustomLoading = () => <CustomLoadingStyle><div></div><div></div><div></div></CustomLoadingStyle>