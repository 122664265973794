import React from "react";
import styled from "styled-components";

interface Props {
  rotate?: boolean
}
export const RightArrow = ({ rotate }: Props) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 20 20"
    >
      <Path d="M6 15l5-5l-5-5l1-2l7 7l-7 7z" />
    </Svg>
  );
};

const Svg = styled.svg`
  &:hover {
    filter: opacity(0.5);
  }
  &:focus {
    filter: opacity(0.5);
  }
`;

const Path = styled.path`
  fill: black;
`;