import { useQuery, useMutation } from "@apollo/client";
import { GET_SUBCATEGORIES, GET_SUBCATEGORIES_COMPLETE } from '../graphql/queries'
import { CREATE_SUBCATEGORY, EDIT_SUBCATEGORY, DELETE_SUBCATEGORY } from "../graphql/mutations"
import { CreateSubCategoryInput, EditSubCategoryInput, SubCategory, SubCategoryData, DeleteSubCategoryInput } from '../models'
import { useState } from "react";

interface Filters {
    _id?: string
    homePage?: boolean
}

export const useSubCategory = (input: Filters = {}) => {
    const { _id, homePage } = input
    const getFullData = !!_id || !!homePage
    const [subCategories, setSubCategories] = useState<SubCategory[]>([])
    const { loading } = useQuery<SubCategoryData>(getFullData ? GET_SUBCATEGORIES_COMPLETE : GET_SUBCATEGORIES, {
        variables: { input },
        fetchPolicy: 'no-cache',
        onCompleted: ({ subCategories: { message, status, node } }) => {
            setSubCategories(node)
        }
    })
    const [createSubCategory, { loading: createLoading, error: createError }] = useMutation<{ createSubCategory: SubCategory }, CreateSubCategoryInput>(CREATE_SUBCATEGORY);
    const [editSubCategory, { loading: editLoading, error: editError }] = useMutation<{ editSubCategory: SubCategory }, EditSubCategoryInput>(EDIT_SUBCATEGORY);
    const [deleteSubCategory, { loading: deleteSubCategoryLoading, error: deleteError }] = useMutation<{ deleteSubCategory: SubCategoryData }, DeleteSubCategoryInput>(DELETE_SUBCATEGORY);

    return { subCategories, loading, createSubCategory, mutationLoading: createLoading || editLoading, createError, editSubCategory, editError, deleteSubCategory, deleteSubCategoryLoading, deleteError }
}