import { gql } from "@apollo/client"

export const EDIT_ITEM = gql`
mutation editItem($input: EditItemInput) {
  editItem(input: $input) {
    _id
    subCategory{
      name
      _id
    }
    name
    images
    description
    additionalData {
      name
      images
      description
      specs{
        name
        value
      }
    }
  }
}
`

export const DELETE_ITEM = gql`
mutation deleteItem($id: ID!) {
  deleteItem(_id: $id) {
    status
    message
    node {
      _id
      name
    }
  }
}
`

export const DELETE_SUBCATEGORY = gql`
mutation deleteSubCategory($_id: ID!) {
  deleteSubCategory(_id: $_id) {
    status
    message
    node {
      _id
      name
    }
  }
}
`

export const DELETE_CATEGORY = gql`
mutation deleteCategory($_id: ID!) {
  deleteCategory(_id: $_id) {
    status
    message
    node {
      _id
      name
    }
  }
}
`

export const EDIT_CATEGORY = gql`
  mutation editCategory($_id: ID!, $name: String!) {
    editCategory(_id: $_id, name: $name) {
        _id
        name
      }
  }
`

export const CREATE_ITEM = gql`
mutation createItem($input: CreateItemInput) {
  createItem(input: $input) {
    _id
    subCategory {
      _id
      name
    }
    name
    images
    description
    additionalData {
      name
      images
      description
    }
  }
}
`
export const DELETE_CARD = gql`
mutation deleteCard($id: ID!) {
  deleteCard(_id: $id) {
    status
    message
    node {
      _id
      backgroundImage
      title
      content
      buttonText
      redirectTo {
        type
        value
      }
      sliderName
      displayOrder
    }
  }
}
`
export const EDIT_CARD = gql`
mutation editCard($input: EditCardInput) {
  editCard(input: $input) {
    status
    message
    node {
      _id
      backgroundImage
      title
      content
      buttonText
      redirectTo {
        type
        value
      }
      sliderName
      displayOrder
    }
  }
}
`
export const CREATE_CARD = gql`
mutation createCard($input: CreateCardInput) {
  createCard(input: $input) {
    status
    message
    node {
      _id
      backgroundImage
      title
      content
      buttonText
      redirectTo {
        type
        value
      }
      sliderName
      displayOrder
    }
  }
}
`

export const CREATE_CONTACT_EMAIL = gql`
mutation createContactEmail($input: CreateContactEmailInput) {
  createContactEmail(input: $input) {
    success
  }
}
`

export const CREATE_FORGOTTEN_PASSWORD_INQUIRIES = gql`
  mutation createForgottenPasswordInquiry($input: CreateForgottenPasswordInquiryInput!) {
    createForgottenPasswordInquiry(input: $input) {
      email
    }
  }
`

export const RESET_PASSWORD = gql`
mutation resetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    _id
    email
    isAdmin
    token {
      value
      exp
    }
  }
}
`

export const CREATE_SUBCATEGORY = gql`
  mutation createSubCategory($input: CreateSubCategoryInput!) {
    createSubCategory(input: $input) {
      _id
    }
  }
`

export const EDIT_SUBCATEGORY = gql`
mutation editSubCategory($input: EditSubCategoryInput!) {
  editSubCategory(input : $input) {
    _id
  }
}
`

export const CREATE_CATEGORY = gql`
  mutation createCategory($name: String!) {
    createCategory(name: $name) {
      _id
    }
  }
`

export const EDIT_ABOUTUS = gql`
mutation Mutation($input: AboutUsInput!) {
  editAboutUs(input: $input) {
    status
    message
    node {
      _id
      title
      content
    }
  }
}
`
export const EDIT_PERSON = gql`
mutation Mutation($input: EditPersonInput!) {
  editPerson(input: $input) {
    status
    message
    node {
      _id
      name
      image
    }
  }
}
`
export const CREATE_PERSON = gql`
mutation CreatePerson($input: CreatePersonInput!) {
  createPerson(input: $input) {
    status
    message
    node {
      _id
      name
      image
    }
  }
}
`

export const DELETE_PERSON = gql`
mutation DeletePerson($id: ID!) {
  deletePerson(_id: $id) {
    status
    message
    node {
      _id
      name
      image
    }
  }
}
`

export const EDIT_OURTEAM_TITLE = gql`
mutation EditTeam($title: String!) {
  editTeam(title: $title) {
    status
    message
    node {
      title
    }
  }
}
`

export const CREATE_CLIENT = gql`
mutation CreateClient($input: CreateClientInput!) {
  createClient(input: $input) {
    status
    message
    node {
      _id
      image
    }
  }
}
`

export const DELETE_CLIENT = gql`
mutation DeleteClient($id: ID!) {
  deleteClient(_id: $id) {
    status
    message
    node {
      _id
      image
    }
  }
}
`




