import { gql } from "@apollo/client"

export const GET_SLIDERCARD = gql`
query SliderCard($sliderName: String!) {
  sliderCard(sliderName: $sliderName) {
    status
    message
    node {
      _id
      backgroundImage
      title
      content
      buttonText
      redirectTo {
        type
        value
      }
      sliderName
      displayOrder
    }
  }
}
`

export const GET_CATEGORY = gql`
query Categories {
  categories {
    status
    message
    node {
      _id
      name
      subCategories {
        _id
        name
      }
    }
  }
}
`
export const GET_ITEMS = gql`
query Items($input: itemInput) {
  items(input: $input) {
    status
    message
    node {
      name
      _id
      subCategory {
        name
      }
      images
    }
  }
}
`

export const GET_ITEM = gql`
query Item($id: ID!) {
  item(_id: $id) {
    status
    message
    node {
      _id
      subCategory {
        _id
        name
      }
      name
      images
      description
      additionalData {
        name
        description
        images
        specs {
          name
          value
        }
      }
    }
  }
}
`

export const GET_SUBCATEGORIES = gql`
query SubCategories($input: GetSubCategoryInput!){
  subCategories(input: $input){
    status
    message
    node {
      _id
      name

    }
  }
}
`

export const GET_SUBCATEGORIES_COMPLETE = gql`
query SubCategories ($input: GetSubCategoryInput!) {
  subCategories (input: $input){
    status
    message
    node {
      _id
      name
      homePage
      image
    }
  }
}
`

export const LOGIN = gql`
query Login($input: LoginInput!) {
  login(input: $input) {
    status
    message
    node {
      _id
      email
      isAdmin
      token {
        value
        exp
      }
    }
  }
}
`

export const GET_USER = gql`
query User($token: String!) {
  user(token: $token) {
    status
    message
    node {
      _id
      email
      isAdmin
      token {
        value
        exp
      }
    }
  }
}
`

export const GET_ABOUTUS = gql`
query Query {
  aboutUs {
    status
    message
    node {
      title
      content
      _id
    }
  }
}
`

export const GET_OURTEAM = gql`
query Query {
  ourTeam {
    status
    message
    node {
      title
      people {
        _id
        name
        image
      }
    }
  }
}
`

export const GET_CLIENTS = gql`
query Query {
  clients {
    status
    message
    node {
      _id
      image
    }
  }
}
`