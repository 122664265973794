/* eslint-disable no-restricted-globals */
import { MouseEvent, useContext } from "react";
import { components } from "react-select";
import { UserContext } from "../../hooks/userContext";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai"
import { StorePopUpContext } from "../../hooks/storePopUp";
import { Category, SubCategory } from "../../models";

export const Option = (props: any) => {
  const { isAdmin } = useContext(UserContext)
  const { setShowCreateSubCategory, setDefaultSubCategoryId, setDefaultSubCategoryName, setDefaultCategoryId, setDefaultCategoryName, setShowDeleteSubCategory } = useContext(StorePopUpContext)
  const handleEdit = (e: MouseEvent<HTMLLabelElement>) => {
    e.stopPropagation()
    setShowCreateSubCategory(true)
    const category: Category = props.options.find((category: Category) => {
      const algo = category.subCategories.find((subCategory: SubCategory) => {
        return subCategory._id === props.value
      })
      return !!algo
    })
    setDefaultCategoryName(category.name)
    setDefaultCategoryId(category._id)
    setDefaultSubCategoryName(props.label)
    setDefaultSubCategoryId(props.value)
  }

  const handleDelete = (e: MouseEvent<HTMLLabelElement>) => {
    e.stopPropagation()
    setShowDeleteSubCategory(true)
    setDefaultSubCategoryName(props.label)
    setDefaultSubCategoryId(props.value)
  }

  return (
    <div>
      <components.Option {...props}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <div>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />{" "}
            <label>{props.label}</label>
            {isAdmin &&
              <label onClick={handleEdit} style={{ padding: '10px', cursor: 'pointer' }}>
                <AiOutlineEdit style={{ marginLeft: "10px", alignSelf: "flex-end" }} />
              </label>}
          </div>
          {isAdmin && <label onClick={handleDelete} style={{ cursor: 'pointer', paddingInline: '10px' }}>
            <AiOutlineDelete style={{ marginLeft: "10px", alignSelf: "flex-end" }} />
          </label>
          }
        </div>
      </components.Option>
    </div>
  );
};