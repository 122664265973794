import { Dispatch, FunctionComponent, SetStateAction, useState, useEffect, useContext, useRef } from "react";
import { SliderCard } from "./SliderCard";
import styled from "styled-components";
import { RightArrow } from "../assets/right-arrow";
import { LeftArrow } from "../assets/left-arrow";
import { Loading } from "./Loading"
import { useSliderCard } from "../hooks/useSliderCard";
import { Card, NewCard } from "../models";
import { useCardMutation } from "../hooks/useCardMutation";
import { UserContext } from "../hooks/userContext";

//assets

export const Slider: FunctionComponent = () => {
  const SliderName = "HomeSlider"
  const [cards, setCards] = useState<Card[] | NewCard[]>([])
  const { loading } = useSliderCard(SliderName, setCards as Dispatch<SetStateAction<Card[]>>)

  const { updateCard, deleteThisCard, loading: loadingMutation } = useCardMutation()
  const numberOfCards = cards.length
  const [provisionalId, setProvisionalId] = useState(numberOfCards + 1)

  const { isAdmin } = useContext(UserContext)

  const [diffX, setDiffX] = useState(0)
  const [dragging, setDragging] = useState(false)
  const [currentLeft, setCurrentLeft] = useState(0)
  const cardRef = useRef<HTMLDivElement | null>(null)

  const dragStart = (e: any) => {
    if (!cardRef?.current) return
    cardRef.current.style.transitionDuration = '0s'
    const x = e?.screenX || e.changedTouches[0].clientX
    setDiffX(x - e.currentTarget.getBoundingClientRect().left)
    setDragging(true)
  }
  const dragEnd = () => {
    const w = window.innerWidth;
    if (!cardRef?.current) return
    cardRef.current.style.transitionDuration = '0.5s'
    const relativeLeft = (currentLeft + w * currentCard)
    if (relativeLeft > 0) {
      if (relativeLeft > w / 4) {
        handlePrevious()
      } else {
        setCurrentLeft(-currentCard * w)
      }

    }
    if (relativeLeft < 0) {
      if (-relativeLeft >= w / 4 && currentCard < cards.length) {
        handleNext()
      } else {
        setCurrentLeft(-currentCard * w)
      }
    }
    setDragging(false)
  }
  const onDragging = (e: any) => {

    if (dragging) {
      const w = window.innerWidth
      const x = e?.screenX || e.changedTouches[0].clientX
      let left = x - diffX - w * currentCard
      if (currentCard === 0 && left > 0) return
      if (currentCard === cards.length - 1 && left + w * currentCard < 0) return
      setCurrentLeft(left)
    }
  }

  const [currentCard, setCurrentCard] = useState(0)
  const handlePrevious = () => {
    if (currentCard === 0) {
      setCurrentCard(cards.length - 1)
      setCurrentLeft(-window.innerWidth * (cards.length - 1))
      return
    }
    setCurrentLeft(-window.innerWidth * (currentCard - 1))
    setCurrentCard(currentCard - 1)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleNext = () => {
    setCurrentLeft(-window.innerWidth * ((currentCard + 1) % cards.length))
    setCurrentCard(prevCard => (prevCard + 1) % cards.length)
  }

  const handleCircle = (index: number) => {
    setCurrentLeft(-window.innerWidth * (index % cards.length))
    setCurrentCard(index)
  }


  useEffect(() => {
    if (!isAdmin) {
      const interval = setInterval(() => {
        handleNext();
      }, 6000);
      return () => clearInterval(interval);
    }
  }, [handleNext, isAdmin])

  const handleUpdateCard = async (newCard: NewCard | Card, index: number) => {
    const id = (cards[index] as Card)?._id
    if (id) {
      newCard = { ...newCard, _id: id } as Card
    }
    const result = await updateCard(newCard)
    const updatedCards = [...cards]
    if (!result?.node) return
    const createdCard = result.node as Card
    updatedCards[index] = createdCard
    setCards(updatedCards)
  }

  const deleteCard = async (index: number) => {
    let newCards = [...cards]
    newCards.splice(index, 1)
    for (let i = index; i < newCards.length; i = i + 1) {
      newCards[i].displayOrder = newCards[i].displayOrder - 1
    }
    setCards(newCards)
    const id = (cards[index] as Card)?._id
    if (id) {
      await deleteThisCard(id)
    }
    setCurrentCard(index - 1)
  }

  const createCard = () => {
    const newCard: NewCard = {
      provisionalId: provisionalId,
      backgroundImage: '',
      title: '',
      buttonText: '',
      redirectTo: {
        type: 'anchor',
        value: '/'
      },
      content: '',
      displayOrder: numberOfCards,
      sliderName: cards[1].sliderName
    }
    setProvisionalId(provisionalId + 1)
    setCards([...cards, newCard])
    setCurrentCard(numberOfCards)
  }

  if (loading) {
    return <Loading side='70px' />
  }

  return (
    <Container >
      <Previous onClick={handlePrevious}><LeftArrow /></Previous>
      <Next onClick={handleNext}><RightArrow /></Next>
      <Options>
        {cards && cards.map((card, index) => {
          return <Circle key={index} currentCard={index === currentCard} onClick={() => handleCircle(index)} />
        })}
      </Options>
      <ViewPort onMouseDown={dragStart} onMouseMove={onDragging} onMouseUp={dragEnd} onTouchStart={dragStart} onTouchEnd={dragEnd} onTouchMove={onDragging}>
        <CardContainer ref={cardRef} left={currentLeft} currentCard={currentCard}>
          {cards && (cards as Card[]).map(({ backgroundImage, title, content, buttonText, redirectTo, displayOrder, _id, sliderName, provisionalId }, index) => {
            return <SliderCard
              key={_id || provisionalId}
              backgroundImage={backgroundImage}
              title={title}
              content={content}
              buttonText={buttonText}
              redirectTo={redirectTo}
              displayOrder={displayOrder}
              handleUpdateCard={(newCard) => handleUpdateCard(newCard, index)}
              deleteCard={() => deleteCard(index)}
              createCard={createCard}
              _id={_id}
              numberOfCards={numberOfCards}
              sliderName={sliderName}
              index={index}
              loading={loadingMutation}
            />
          })}
        </CardContainer>
      </ViewPort>
    </Container>
  )
}
const Options = styled.div`
  position: absolute;
  top: calc(80vh - 10vh + 100px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`

interface CircleProps {
  currentCard: boolean
}

const Circle = styled.span<CircleProps>`
  height: 20px;
  width: 20px;
  padding: 0;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 5px;
  ${({ currentCard }) => currentCard ? `background-color: #fff` : `background-color: transparent`};
  box-shadow: 
    12px 12px 16px 0 rgba(255, 255, 255, 0.3) inset,
    -8px -8px 12px 0 rgba(0, 0, 0, .25) inset;
`

const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100%
`
const Previous = styled.div`
  top: 50%;
  left: 15px;
  position: absolute;
  z-index: 10;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 4px;
  box-shadow: 
    12px 12px 16px 0 rgba(255, 255, 255, 0.3) inset,
    -8px -8px 12px 0 rgba(0, 0, 0, .25) inset;
`;

const Next = styled.div`
  top: 50%;
  right: 15px;
  position: absolute;
  z-index: 10;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
  box-shadow: 
    12px 12px 16px 0 rgba(255, 255, 255, 0.3) inset,
    -8px -8px 12px 0 rgba(0, 0, 0, .25) inset;
`;

const ViewPort = styled.div`
  position: relative;
  width: calc(100vw * 4);
  top: 120px;
  bottom: 10vh;
  overflow: hidden;
  height: calc(100vh - 10vh - 120px);
`;

interface CardContainerProps {
  currentCard: number
  left: number
}

const CardContainer = styled.div<CardContainerProps>`
  position: absolute;
  display: flex;
  height: calc(100vh - 10vh - 120px);
  flex-direction: row;
  width: fit-content;
  left: ${({ left }) => `${left}px`};
  transition-duration: 0.5s;
  /* ${({ currentCard, left }) => `-webkit-transform: translate(calc(${left}px));`} */
  /* ::before {
    content: '';
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  } */
`;