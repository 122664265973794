import { keyframes } from "styled-components";

export const appearFromBotton = keyframes`
  0% { opacity: 0; transform: translateY(40px); }
  100% { opacity: 1; transform: translateY(0px); }
`

export const beat = keyframes`
  0% { transform: none; }
  50% { transform: scale(1.1); }
  100% { transform: none; }
`

export const zoom = keyframes`
  100% { transform: scale(1.1); }
`