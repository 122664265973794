import { useQuery } from "@apollo/client";
import { GET_SLIDERCARD } from '../graphql/queries'
import { useNavigate } from "react-router-dom";
import { SliderCardData, SliderCardVars, Card } from "../models";
import { Dispatch, SetStateAction } from "react";


export const useSliderCard = (sliderName: string, setCards: Dispatch<SetStateAction<Card[]>>) => {
    const navigate = useNavigate()
    const { loading } = useQuery<SliderCardData, SliderCardVars>(GET_SLIDERCARD, {
        variables: { sliderName: "HomeSlider" },
        onCompleted: ({ sliderCard: { message, status, node } }) => {
            if (message) {
                navigate("/not-found", { state: { message, status } })
            }
            setCards(node)
        }
    })
    return { loading }
}