import { QueryResponse } from "./QueryResponse";

export class CreateCardInput {
    backgroundImage: string
    title: string
    content: string
    buttonText: string
    redirectTo: RedirectTo
    sliderName: string
    displayOrder: number
    constructor(card: NewCard) {
        this.backgroundImage = card.backgroundImage
        this.title = card.title
        this.content = card.content
        this.buttonText = card.buttonText
        this.redirectTo = card.redirectTo
        this.sliderName = card.sliderName
        this.displayOrder = card.displayOrder
    }
}

export class CreateEditCardInput extends CreateCardInput {
    _id: string
    constructor(card: Card) {
        super(card);
        this._id = card._id
    }
}

export interface NewCard {
    backgroundImage: string
    title: string
    content: string
    buttonText: string
    redirectTo: RedirectTo
    sliderName: string
    displayOrder: number
    provisionalId?: number
}
export interface Card extends NewCard {
    _id: string
}
export interface RedirectTo {
    type: RedirectType
    value: string
}

export type RedirectType = "anchor" | "route"

export interface SliderCard {
    _id?: string
    cards: Card[]
}

export interface SliderCardData {
    sliderCard: QueryResponse<Card[]>
}

export interface SliderCardVars {
    sliderName: string
}