import styled from "styled-components"
import Select from 'react-select';
import { FunctionComponent, useEffect, useState } from "react";
import { RedirectType } from "../../models";

interface Props {
    handleSubmit: (type: RedirectType, value: string) => void
    value: string
}

interface Option {
    value: string
    label: string
}

export const HomePage: FunctionComponent<Props> = ({ handleSubmit, value }) => {

    const [link, setLink] = useState<string>("")
    const [selectValue, setSelectValue] = useState<Option | null>()

    useEffect(() => {
        const selected = options.find((option) => `#${option.value}` === value)
        setSelectValue(selected)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const options = [
        {
            value: 'services',
            label: 'Nuestros servicios'
        },
        {
            value: 'about-us',
            label: 'Sobre Nosotros'
        },
        {
            value: 'our-team',
            label: 'Nuestro Equipo'
        },
        {
            value: 'contact',
            label: 'Contactanos'
        },
    ]
    const handleSelect = (event: any) => {
        setSelectValue(event)
        setLink(`#${event.value}`)
    }
    const submitRoute = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        e.preventDefault()
        handleSubmit('anchor', link)
    }

    return <Form>
        <Title>Link a sección de inicio</Title>
        <Select
            options={options}
            onChange={handleSelect}
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 99999999999 }) }}
            value={selectValue} />
        <div style={{ display: 'flex', width: "100%", justifyContent: 'center' }}>
            <Button onClick={(e) => submitRoute(e)}>Guardar</Button>
        </div>
    </Form >
}

const Form = styled.div`
    display:flex;
    flex-direction:column;
    gap: 20px;
`
const Title = styled.h3`
    padding:0;
    margin:0;
`

interface ButtonProps {
    shaddowColor?: string
    disabled?: boolean
}
const Button = styled.button<ButtonProps>`
  width: 45%;
  border: none;
  padding: 12px;
  background: ${({ disabled }) => disabled ? "#9d5731" : "#e5834d"};
  border-radius: 10px;
  color: white;
  font-size: 15px;
  height: 40px;
  cursor: ${({ disabled }) => disabled ? "progress" : "pointer"};
  margin: 0px 0px 10px 0px;
  &:hover{
    box-shadow: ${({ shaddowColor, disabled }) => !disabled && (shaddowColor || "white 0px 0px 4px 0px")} 
  }
  @media (max-width: 800px) {
    width: 90%;
  }
`