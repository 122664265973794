import { useQuery } from "@apollo/client";
import { useState } from "react";
import { GET_OURTEAM } from '../graphql/queries'
import { OurTeam, Person, QueryResponse } from "../models";

export const useOurTeam = () => {

    const [title, setTitle] = useState('')
    const [people, setPeople] = useState<Person[]>([])

    const { loading: queryLoading } = useQuery<{ ourTeam: QueryResponse<OurTeam> }>(GET_OURTEAM,
        {
            fetchPolicy: "no-cache",
            onCompleted: ({ ourTeam: { message, status, node } }) => {
                if (node) {
                    const { title, people } = node
                    setTitle(title)
                    setPeople(people)
                }
            }
        })
    return { queryLoading, people, title, setPeople }
}