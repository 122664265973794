import { useState, FormEvent } from "react"
import styled from "styled-components"
import { useContact } from "../hooks/useContact"
import { Button } from "./Button"
import { Input } from "./Input"
import { Loading } from "./Loading"
import { NotificationManager } from 'react-notifications';

const ContactForm = () => {
  const { createContact, loading } = useContact()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [query, setQuery] = useState('')

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    await createContact({
      variables: {
        input: {
          name,
          email,
          phoneNumber,
          query
        }
      }
    })
    setName('')
    setEmail('')
    setPhoneNumber('')
    setQuery('')
    NotificationManager.success("Su mensaje ha sido enviado con éxito", "Contacto")
  }

  return (
    <>
      <Form onSubmit={handleSubmit} isLoading={loading}>
        <Loading isLoading={loading} side="40px" />
        <Input id="name" label="Nombre" type="string" setValue={setName} value={name} required />
        <Input id="email" label="Email" type="email" setValue={setEmail} value={email} required />
        <Input id="phone-number" label="Teléfono" type="string" setValue={setPhoneNumber} value={phoneNumber} required />
        <Input id="message" label="Consulta" type="textarea" setValue={setQuery} value={query} required rows={10} />
        <Button type="submit">Enviar</Button>
      </Form>
    </>

  )
}

interface FormProps {
  isLoading: boolean
}
const Form = styled.form<FormProps>`
  height: max-content;
  width: 80%;
  gap: 30px;
  display:flex;
  flex-direction:column;
  margin: 10px;
  padding: 27px 10px 10px 10px;
  box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
  backdrop-filter: blur( 4px );
  border: 1px solid rgba( 255,255,255,0.18 );
  border-radius: 30px;
  background-color: rgba( 255,255,255,0.25 );
  ${({ isLoading }) => isLoading && `
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    right: 0;
    bottom: 0;
    z-index: 11;
    border-radius: 30px;
  }
  `}
  
`

export default ContactForm