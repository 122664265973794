import styled from "styled-components"
import { FunctionComponent, useState } from "react";
import { Input } from "../Input";
import { RedirectType } from "../../models";

interface Props {
    handleSubmit: (type: RedirectType, value: string) => void
    value: string | undefined
}

export const External: FunctionComponent<Props> = ({ handleSubmit, value }) => {

    const [link, setLink] = useState<string | undefined>(value?.slice(7))

    const handleLink = (value: (string | undefined)) => {
        setLink(value?.slice(7))
    }

    const submitRoute = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        e.preventDefault()
        const savedLink = 'http://' + link
        handleSubmit('anchor', savedLink as string)
    }

    return <Form>
        <Title>Link Externo</Title>
        <Input label="Link externo" type="url" value={'http://' + link} setValue={handleLink} />
        <div style={{ display: 'flex', width: "100%", justifyContent: 'center' }}>
            <Button onClick={(e) => submitRoute(e)}>Guardar</Button>
        </div>
    </Form>
}

const Form = styled.div`
    display:flex;
    flex-direction:column;
    gap: 20px;
`
const Title = styled.h3`
    padding:0;
    margin:0;
`

interface ButtonProps {
    shaddowColor?: string
    disabled?: boolean
}
const Button = styled.button<ButtonProps>`
  width: 45%;
  border: none;
  padding: 12px;
  background: ${({ disabled }) => disabled ? "#9d5731" : "#e5834d"};
  border-radius: 10px;
  color: white;
  font-size: 15px;
  height: 40px;
  cursor: ${({ disabled }) => disabled ? "progress" : "pointer"};
  margin: 0px 0px 10px 0px;
  &:hover{
    box-shadow: ${({ shaddowColor, disabled }) => !disabled && (shaddowColor || "white 0px 0px 4px 0px")} 
  }
  @media (max-width: 800px) {
    width: 90%;
  }
`