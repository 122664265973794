
// Styles
import 'react-notifications/lib/notifications.css';
// dependencies
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { lazy, Suspense } from "react"
import { NotificationContainer } from "react-notifications"
//Provider
import StorePopUpProvider from "./hooks/storePopUp";
// components
import { Header } from "./components";
import { Footer } from "./components";
import { ScrollToTop } from "./components/ScrollToTop";
import { Loading } from "./components/Loading"
import { Shortcut } from "./components/Shortcut";
// pages
const Home = lazy(() => import("./pages/Home"));
const Store = lazy(() => import("./pages/Store"));
const Error = lazy(() => import("./pages/404"));
const Login = lazy(() => import("./pages/Auth/Login"));
const Auth = lazy(() => import("./pages/Auth"));
const ForgotPassword = lazy(() => import("./pages/Auth/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/Auth/ResetPassword"));
const WebMail = lazy(() => import("./pages/Webmail"));
const ItemComponent = lazy(() => import("./pages/Store/Item"));
// import { Cart } from "./pages/Cart";
const App = () => {

  return (
    <BrowserRouter>
      <NotificationContainer />
      <ScrollToTop>
        <Suspense fallback={<Loading side='70px' />} >
          <Routes>
            <Route path="/" element={<><Header /><Shortcut /><Footer /></>}>
              <Route index element={<Home />} />
              {/* <Route path="cart" element={<Cart />} /> */}
              <Route path="store" element={<StorePopUpProvider><Store /></StorePopUpProvider>} />
              <Route path='/webmail' element={<WebMail />} />
              <Route path="store/:itemId" element={<ItemComponent />} />
              <Route path="store/create" element={<ItemComponent />} />
              <Route path="not-found" element={<Error />} />
            </Route>
            <Route path="/auth" element={<Auth />}>
              <Route path="login" element={<Login />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password/:id" element={<ResetPassword />} />
            </Route>
            <Route path="*" element={<Navigate to="/not-found" />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
